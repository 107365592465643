import React, { useState } from 'react';
import logo from "../assets/images/KSeco.png"
import LoginModal from './LoginModal';
import AuthIndicator from './AuthIndicator.tsx';
import { useAuth } from '../context/AuthContext';

const Header = ({ openLoginModal }) => {
    const { isAuthenticated, user, logout } = useAuth();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleLogout = async () => {
        await logout();
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        // Prevent body scroll when menu is open
        document.body.style.overflow = !isMenuOpen ? 'hidden' : 'auto';
    };

    return (
        <div>
            <div className="top-bar-area inc-pad bg-theme text-light tw-py-3">
                <div className="container tw-mx-auto tw-px-4">
                    <div className="tw-flex tw-flex-wrap tw-items-center tw-justify-between">
                        <div className="tw-flex tw-items-center tw-space-x-6">
                            <div className="tw-flex tw-items-center tw-space-x-2">
                                <i className="fas fa-map-marker-alt tw-text-sm"></i>
                                <span>Kosova, Peje 30000</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-space-x-2">
                                <i className="fas fa-envelope-open tw-text-sm"></i>
                                <span>Info@gmail.com</span>
                            </div>
                        </div>
                        <div className="tw-flex tw-items-center tw-space-x-6">
                            <div className="tw-flex tw-items-center tw-space-x-2">
                                <i className="fas fa-clock tw-text-sm"></i>
                                <span>Office Hours: 8:00 AM – 7:45 PM</span>
                            </div>
                            <div className="tw-flex tw-items-center tw-space-x-4">
                                <a href="#" className="tw-text-white hover:tw-text-gray-200"><i className="fab fa-facebook-f"></i></a>
                                <a href="#" className="tw-text-white hover:tw-text-gray-200"><i className="fab fa-twitter"></i></a>
                                <a href="#" className="tw-text-white hover:tw-text-gray-200"><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <header id="home">
                <nav className="navbar navbar-default active-border navbar-sticky bootsnav tw-bg-white">
                    <div className="container tw-mx-auto tw-px-4 tw-flex tw-items-center tw-justify-between tw-h-[90px]">
                        <div className="navbar-header tw-flex tw-items-center">
                            <button 
                                type="button" 
                                className="navbar-toggle tw-mr-4 tw-p-2 tw-rounded-lg hover:tw-bg-gray-100 tw-transition-colors md:tw-hidden" 
                                onClick={toggleMenu}
                                aria-label="Toggle navigation"
                            >
                                <i className="fa fa-bars tw-text-[#438a5e] tw-text-2xl"></i>
                            </button>
                            <a className="navbar-brand" href="index.html">
                                <img src={logo} className="logo tw-h-[45px] tw-w-auto" alt="Logo" />
                            </a>
                        </div>
                        
                        {/* Mobile Menu Overlay - Only visible on mobile */}
                        {isMenuOpen && (
                            <div 
                                className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-40 md:tw-hidden"
                                onClick={toggleMenu}
                            ></div>
                        )}
                        
                        {/* Mobile Menu Panel - Only visible on mobile */}
                        <div className={`tw-fixed tw-top-0 tw-left-0 tw-h-full tw-w-[280px] tw-bg-white tw-z-50 tw-transform ${isMenuOpen ? 'tw-translate-x-0' : '-tw-translate-x-full'} tw-transition-transform tw-duration-300 tw-ease-in-out tw-overflow-y-auto md:tw-hidden`}>
                            {/* Mobile Menu Header */}
                            <div className="tw-flex tw-items-center tw-justify-between tw-p-4 tw-border-b tw-border-gray-200">
                                <img src={logo} className="tw-h-8 tw-w-auto" alt="Logo" />
                                <button 
                                    onClick={toggleMenu}
                                    className="tw-p-2 tw-rounded-full hover:tw-bg-gray-100 tw-transition-colors"
                                >
                                    <i className="fas fa-times tw-text-gray-600 tw-text-xl"></i>
                                </button>
                            </div>
                            
                            {/* Mobile Menu Items */}
                            <div className="tw-py-4">
                                <a href="/" className="tw-flex tw-items-center tw-px-6 tw-py-3 tw-text-gray-800 hover:tw-bg-gray-50 tw-transition-colors">
                                    <i className="fas fa-home tw-w-6 tw-text-[#438a5e]"></i>
                                    <span className="tw-ml-3">Home</span>
                                </a>
                                <a href="#" className="tw-flex tw-items-center tw-px-6 tw-py-3 tw-text-gray-800 hover:tw-bg-gray-50 tw-transition-colors">
                                    <i className="fas fa-building tw-w-6 tw-text-[#438a5e]"></i>
                                    <span className="tw-ml-3">Company</span>
                                </a>
                                <a href="/services" className="tw-flex tw-items-center tw-px-6 tw-py-3 tw-text-gray-800 hover:tw-bg-gray-50 tw-transition-colors">
                                    <i className="fas fa-cog tw-w-6 tw-text-[#438a5e]"></i>
                                    <span className="tw-ml-3">Services</span>
                                </a>
                                <a href="/contact" className="tw-flex tw-items-center tw-px-6 tw-py-3 tw-text-gray-800 hover:tw-bg-gray-50 tw-transition-colors">
                                    <i className="fas fa-envelope tw-w-6 tw-text-[#438a5e]"></i>
                                    <span className="tw-ml-3">Contact</span>
                                </a>
                                
                                {/* Mobile Auth Button */}
                                <div className="tw-px-6 tw-py-4 tw-mt-2 tw-border-t tw-border-gray-200">
                                    {isAuthenticated ? (
                                        <div className="tw-space-y-3">
                                            <div className="tw-flex tw-items-center tw-space-x-3 tw-px-2 tw-py-2 tw-bg-gray-50 tw-rounded-lg">
                                                <div className="tw-w-10 tw-h-10 tw-rounded-full tw-bg-[#438a5e] tw-flex tw-items-center tw-justify-center">
                                                    <span className="tw-text-white tw-font-semibold">
                                                        {user?.username?.[0] || user?.email?.[0] || 'U'}
                                                    </span>
                                                </div>
                                                <div>
                                                    <div className="tw-font-medium tw-text-gray-900">
                                                        {user?.username || user?.email || 'User'}
                                                    </div>
                                                    <div className="tw-text-sm tw-text-gray-500">
                                                        {user?.email}
                                                    </div>
                                                </div>
                                            </div>
                                            <button 
                                                onClick={handleLogout}
                                                className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-[#438a5e] tw-rounded-lg hover:tw-bg-[#2c5c3d] tw-transition-colors"
                                            >
                                                <i className="fas fa-sign-out-alt tw-mr-2"></i>
                                                Logout
                                            </button>
                                        </div>
                                    ) : (
                                        <button 
                                            onClick={() => {
                                                toggleMenu();
                                                openLoginModal();
                                            }}
                                            className="tw-w-full tw-flex tw-items-center tw-justify-center tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-[#438a5e] tw-rounded-lg hover:tw-bg-[#2c5c3d] tw-transition-colors"
                                        >
                                            <i className="fas fa-user tw-mr-2"></i>
                                            Login
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        
                        {/* Desktop Menu */}
                        <div className="collapse navbar-collapse !tw-flex !tw-items-center !tw-h-[90px] tw-hidden md:!tw-flex" id="navbar-menu">
                            <ul className="nav navbar-nav tw-flex tw-items-center tw-mx-auto tw-space-x-6 !tw-h-[90px]">
                                <li><a href="/" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Home</a></li>
                                <li><a href="#" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Company</a></li>
                                <li><a href="/services" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Services</a></li>
                                <li><a href="/contact" className="tw-text-gray-800 hover:tw-text-[#438a5e]">Contact</a></li>
                                <li className="tw-relative tw-h-[90px]">
                                    <div className="tw-absolute tw-top-1/2 tw-left-0 tw--translate-y-1/2">
                                        {isAuthenticated ? (
                                            <AuthIndicator 
                                                user={{
                                                    name: user?.username || user?.email || 'User',
                                                    email: user?.email || '',
                                                    avatar: user?.avatar
                                                }} 
                                                onLogout={handleLogout}
                                            />
                                        ) : (
                                            <button 
                                                onClick={openLoginModal}
                                                className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-h-10 tw-px-5 tw-text-sm tw-font-medium tw-text-white tw-rounded-full tw-border-0 tw-outline-none tw-bg-[#438a5e] hover:tw-bg-[#2c5c3d] tw-transition-colors"
                                            >
                                                <i className="fas fa-user"></i>
                                                Login
                                            </button>
                                        )}
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* Contact Info - Only visible on medium and large screens */}
                        <div className="attr-nav inc-border tw-hidden md:tw-flex tw-items-center tw-border-l tw-pl-6 tw-ml-auto">
                            <div className="contact tw-flex tw-items-center tw-space-x-3">
                                <i className="fas fa-phone tw-text-[#438a5e] tw-text-xl"></i>
                                <div>
                                    <p className="tw-text-sm tw-text-gray-500">Call Us Now</p>
                                    <h5 className="tw-text-base tw-font-semibold tw-text-gray-800">+123 456 7890</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
            <LoginModal isOpen={false} onClose={() => {}} />
        </div>
    );
}

export default Header;
