import { Document, Page, Text, View, StyleSheet, Image, pdf } from '@react-pdf/renderer';
import React from 'react';
import QRCode from 'qrcode';
import Logo from '../assets/images/KSeco.png';

// Define colors
const colors = {
  primary: '#438a5e',
  primaryLight: '#5ba577',
  primaryDark: '#2c5c3e',
  secondary: '#2d3748',
  accent: '#edf2f7',
  white: '#FFFFFF',
  gray: '#718096',
  grayLight: '#e2e8f0',
  grayDark: '#4a5568',
  borderColor: '#e2e8f0',
  error: '#e53e3e',
  success: '#38a169',
  background: '#f7fafc'
};

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    paddingBottom: 65,
    fontSize: 9,
    backgroundColor: colors.background,
    fontFamily: 'Helvetica',
    position: 'relative',
  },
  watermarkContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
  },
  watermarkImage: {
    width: 120,
    height: 60,
    opacity: 0.08,
  },
  watermarkTopLeft: {
    position: 'absolute',
    top: '20%',
    left: '20%',
    transform: 'rotate(-30deg)',
  },
  watermarkTopRight: {
    position: 'absolute',
    top: '20%',
    right: '20%',
    transform: 'rotate(30deg)',
  },
  watermarkCenter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(1.5) rotate(0deg)',
  },
  watermarkBottomLeft: {
    position: 'absolute',
    bottom: '20%',
    left: '20%',
    transform: 'rotate(30deg)',
  },
  watermarkBottomRight: {
    position: 'absolute',
    bottom: '20%',
    right: '20%',
    transform: 'rotate(-30deg)',
  },
  header: {
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: 2,
    borderColor: colors.primary,
    paddingBottom: 15,
    marginHorizontal: -10,
    paddingHorizontal: 10,
  },
  headerLogo: {
    width: 100,
    height: 45,
    objectFit: 'contain',
  },
  headerTitle: {
    fontSize: 16,
    color: colors.primary,
    fontFamily: 'Helvetica-Bold',
    textAlign: 'center',
  },
  headerSubtitle: {
    fontSize: 11,
    color: colors.grayDark,
    marginTop: 5,
    textAlign: 'center',
  },
  section: {
    marginBottom: 25,
    backgroundColor: colors.white,
    padding: 15,
    borderRadius: 8,
    border: 1,
    borderColor: colors.grayLight,
  },
  sectionTitle: {
    fontSize: 13,
    color: colors.primary,
    fontFamily: 'Helvetica-Bold',
    marginBottom: 12,
    paddingBottom: 8,
    borderBottom: 1,
    borderColor: colors.grayLight,
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 15,
    marginHorizontal: -5,
  },
  infoCard: {
    width: '31%',
    padding: 8,
    backgroundColor: colors.accent,
    borderRadius: 6,
    marginHorizontal: 5,
  },
  infoLabel: {
    fontSize: 8,
    color: colors.grayDark,
    marginBottom: 4,
    fontFamily: 'Helvetica-Bold',
  },
  infoValue: {
    fontSize: 9,
    color: colors.secondary,
    fontFamily: 'Helvetica',
  },
  table: {
    width: '100%',
    borderRadius: 6,
    overflow: 'hidden',
  },
  tableHeader: {
    backgroundColor: colors.primary,
    flexDirection: 'row',
    padding: 8,
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  tableHeaderCell: {
    color: colors.white,
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
    flex: 1,
    textAlign: 'left',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: 1,
    borderColor: colors.grayLight,
    padding: 8,
    backgroundColor: colors.white,
  },
  tableRowAlt: {
    backgroundColor: colors.accent,
  },
  tableCell: {
    flex: 1,
    fontSize: 8,
    color: colors.secondary,
    textAlign: 'left',
  },
  statusBadge: {
    paddingVertical: 4,
    paddingHorizontal: 8,
    borderRadius: 12,
    alignSelf: 'flex-start',
  },
  statusText: {
    fontSize: 9,
    fontFamily: 'Helvetica-Bold',
  },
  activeStatus: {
    backgroundColor: colors.success + '20',
    color: colors.success,
  },
  inactiveStatus: {
    backgroundColor: colors.error + '20',
    color: colors.error,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 30,
    right: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 10,
    borderTop: 1,
    borderColor: colors.grayLight,
  },
  footerText: {
    fontSize: 8,
    color: colors.gray,
  },
  pageNumber: {
    fontSize: 8,
    color: colors.gray,
  },
  securitySection: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 10,
  },
  qrCode: {
    width: 60,
    height: 60,
  },
  securityText: {
    fontSize: 7,
    color: colors.gray,
    maxWidth: 150,
  },
  infoSection: {
    backgroundColor: colors.white,
    padding: 15,
    borderRadius: 8,
    marginBottom: 25,
  },
  infoGrid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 10,
  },
  infoCard: {
    width: '23%',
    backgroundColor: colors.accent,
    borderRadius: 6,
    padding: 8,
    marginBottom: 10,
  },
  infoCardHighlight: {
    width: '48%',
    backgroundColor: colors.primary + '15',
    borderRadius: 6,
    padding: 10,
    marginBottom: 10,
  },
  infoLabel: {
    fontSize: 8,
    color: colors.grayDark,
    marginBottom: 4,
    fontFamily: 'Helvetica-Bold',
  },
  infoValue: {
    fontSize: 9,
    color: colors.secondary,
    fontFamily: 'Helvetica',
  },
  infoValueHighlight: {
    fontSize: 10,
    color: colors.primary,
    fontFamily: 'Helvetica-Bold',
  },
  divider: {
    height: 1,
    backgroundColor: colors.grayLight,
    marginVertical: 15,
  },
});

// Helper Components
const InfoCard = ({ label, value }) => (
  <View style={styles.infoCard}>
    <Text style={styles.infoLabel}>{label}</Text>
    <Text style={styles.infoValue}>{value || 'N/A'}</Text>
  </View>
);

const StatusBadge = ({ status }) => {
  const isActive = status?.toLowerCase().includes('aktiv');
  return (
    <Text style={[
      styles.statusBadge,
      isActive ? styles.activeStatus : styles.inactiveStatus
    ]}>
      {status}
    </Text>
  );
};

const TableRow = ({ cells, isHeader }) => (
  <View style={[styles.tableRow, isHeader && styles.tableHeader]}>
    {cells.map((cell, index) => (
      <Text 
        key={index} 
        style={isHeader ? styles.tableHeaderCell : styles.tableCell}
      >
        {cell}
      </Text>
    ))}
  </View>
);

// Security Functions
const generateSecurityCode = (data) => {
  const timestamp = new Date().getTime();
  const businessId = data.business?.id || '';
  const randomPart = Math.random().toString(36).substring(2, 8).toUpperCase();
  return `KSC-${timestamp.toString().slice(-6)}-${randomPart}-${businessId.slice(0, 6)}`;
};

const generateQRContent = (data, securityCode) => {
  return JSON.stringify({
    documentType: 'KSECO_BUSINESS_EXTRACT',
    businessId: data.business?.id,
    businessName: data.business?.business_name,
    uniqueIdentifier: data.business?.unique_identifier,
    securityCode: securityCode,
    generatedAt: new Date().toISOString(),
    verificationUrl: `https://kseco.com/verify/${securityCode}`
  });
};

const generateQRCodeImage = async (data) => {
  const securityCode = generateSecurityCode(data);
  const qrContent = generateQRContent(data, securityCode);
  try {
    const qrDataUrl = await QRCode.toDataURL(qrContent, {
      width: 100,
      margin: 2,
      errorCorrectionLevel: 'H'
    });
    return { qrDataUrl, securityCode };
  } catch (err) {
    console.error('Error generating QR code:', err);
    return null;
  }
};

// Main PDF Component
const CompanyRegisterPDF = ({ data }) => {
  const {
    business,
    representatives = [],
    owners = [],
    activities = []
  } = data;

  // Helper function to safely render arrays
  const safeMap = (array, callback) => {
    if (!Array.isArray(array)) return [];
    return array.map(callback);
  };

  // Generate QR code and security code
  const [qrDataUrl, setQrDataUrl] = React.useState('');
  const [securityCode, setSecurityCode] = React.useState('');

  React.useEffect(() => {
    const generateCodes = async () => {
      try {
        const { qrDataUrl: qr, securityCode: sc } = await generateQRCodeImage(data);
        setQrDataUrl(qr);
        setSecurityCode(sc);
      } catch (error) {
        console.error('Error generating QR code:', error);
      }
    };
    generateCodes();
  }, [data]);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Watermark Layer */}
        <View style={styles.watermarkContainer} fixed>
          <Image src={Logo} style={[styles.watermarkImage, styles.watermarkTopLeft]} />
          <Image src={Logo} style={[styles.watermarkImage, styles.watermarkTopRight]} />
          <Image src={Logo} style={[styles.watermarkImage, styles.watermarkCenter]} />
          <Image src={Logo} style={[styles.watermarkImage, styles.watermarkBottomLeft]} />
          <Image src={Logo} style={[styles.watermarkImage, styles.watermarkBottomRight]} />
        </View>

        {/* Header */}
        <View style={styles.header}>
          <Image src={Logo} style={styles.headerLogo} />
          <View>
            <Text style={styles.headerTitle}>{business.business_name || 'N/A'}</Text>
            <Text style={styles.headerSubtitle}>ID: {business.unique_identifier || 'N/A'}</Text>
          </View>
          <StatusBadge status={business.status_in_arbk || 'N/A'} />
        </View>

        {/* Basic Information Section */}
        <View style={styles.infoSection}>
          {/* Primary Info */}
          <View style={styles.infoGrid}>
            <View style={styles.infoCardHighlight}>
              <Text style={styles.infoLabel}>Business Name</Text>
              <Text style={styles.infoValueHighlight}>{business.business_name}</Text>
            </View>
            <View style={styles.infoCardHighlight}>
              <Text style={styles.infoLabel}>Status</Text>
              <Text style={styles.infoValueHighlight}>{business.status_in_arbk}</Text>
            </View>
          </View>

          <View style={styles.divider} />

          {/* Business Details */}
          <View style={styles.infoGrid}>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Business Type</Text>
              <Text style={styles.infoValue}>{business.business_type || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Trade Name</Text>
              <Text style={styles.infoValue}>{business.trade_name || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Unique Identifier</Text>
              <Text style={styles.infoValue}>{business.unique_identifier || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Business Number</Text>
              <Text style={styles.infoValue}>{business.business_number || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Fiscal Number</Text>
              <Text style={styles.infoValue}>{business.fiscal_number || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Registration Date</Text>
              <Text style={styles.infoValue}>{business.registration_date || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Number of Employees</Text>
              <Text style={styles.infoValue}>{business.number_of_employees || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Capital</Text>
              <Text style={styles.infoValue}>{business.capital || 'N/A'}</Text>
            </View>
          </View>

          <View style={styles.divider} />

          {/* Location Details */}
          <View style={styles.infoGrid}>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Municipality</Text>
              <Text style={styles.infoValue}>
                {business.municipality_name || business.related_municipality_data?.municipality_name || 'N/A'}
              </Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Place</Text>
              <Text style={styles.infoValue}>
                {business.place_name || business.related_place_data?.place_name || 'N/A'}
              </Text>
            </View>
            <View style={[styles.infoCard, { width: '48%' }]}>
              <Text style={styles.infoLabel}>Address</Text>
              <Text style={styles.infoValue}>
                {business.address || business.business_addresses?.[0]?.street_address || 'N/A'}
              </Text>
            </View>
          </View>

          <View style={styles.divider} />

          {/* Contact Information */}
          <View style={styles.infoGrid}>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Phone</Text>
              <Text style={styles.infoValue}>{business.phone || business.business_contacts?.[0]?.phone || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Email</Text>
              <Text style={styles.infoValue}>{business.email || business.business_contacts?.[0]?.email || 'N/A'}</Text>
            </View>
            <View style={styles.infoCard}>
              <Text style={styles.infoLabel}>Website</Text>
              <Text style={styles.infoValue}>{business.website || business.business_contacts?.[0]?.website || 'N/A'}</Text>
            </View>
          </View>
        </View>

        {/* Business Activities */}
        {activities && activities.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Business Activities</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['In', 'Ca', 'Ca Activity', 'Code', 'Description', 'Type', 'Sector']}
                isHeader={true}
              />
              {safeMap(activities, (activity, index) => (
                <TableRow
                  key={index}
                  cells={[
                    activity.in || 'N/A',
                    activity.ca || 'N/A',
                    activity.ca_activity || 'N/A',
                    activity.code || 'N/A',
                    activity.description || 'N/A',
                    activity.type || 'N/A',
                    activity.sector || 'N/A'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Representatives */}
        {representatives && representatives.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Representatives</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['In', 'First Name', 'Last Name', 'Position', 'Details', 'Authorizations']}
                isHeader={true}
              />
              {safeMap(representatives, (rep, index) => (
                <TableRow
                  key={index}
                  cells={[
                    rep.In || 'N/A',
                    rep.representative_name || 'N/A',
                    rep.representative_surname || 'N/A',
                    rep.position || 'N/A',
                    rep.representative_details || 'N/A',
                    rep.authorizations || 'N/A'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Owners */}
        {owners && owners.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Owners</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['In', 'Mo', 'Ca', 'Ref', 'Name', 'Capital in Euros', 'Capital Percentage']}
                isHeader={true}
              />
              {safeMap(owners, (owner, index) => (
                <TableRow
                  key={index}
                  cells={[
                    owner.in_owner || 'N/A',
                    owner.mo_owner || 'N/A',
                    owner.ca_owner || 'N/A',
                    owner.ref || 'N/A',
                    owner.name || 'N/A',
                    owner.capitalInEuros || 'N/A',
                    owner.capitalPercentage ? `${owner.capitalPercentage}%` : 'N/A'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Business Addresses */}
        {business.business_addresses && business.business_addresses.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Business Addresses</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['Type', 'Address', 'Postal Code', 'Municipality', 'Is Primary', 'Created', 'In Address', 'CA Address']}
                isHeader={true}
              />
              {safeMap(business.business_addresses, (address, index) => (
                <TableRow
                  key={index}
                  cells={[
                    address.address_type_display || 'N/A',
                    address.street_address || 'N/A',
                    address.postal_code || 'N/A',
                    address.municipality_name || 'N/A',
                    address.is_primary ? 'Yes' : 'No',
                    address.created || 'N/A',
                    address.in_address || 'N/A',
                    address.ca_address || 'N/A'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Business Capitals */}
        {business.business_capitals && business.business_capitals.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Business Capitals</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['Capital Amount', 'Status', 'Reference', 'In Capital', 'CA Capital', 'Created']}
                isHeader={true}
              />
              {safeMap(business.business_capitals, (capital, index) => (
                <TableRow
                  key={index}
                  cells={[
                    capital.capital_amount || 'N/A',
                    capital.active_data_capital ? 'Active' : 'Inactive',
                    capital.ref_capital || 'N/A',
                    capital.in_capital || 'N/A',
                    capital.ca_capital || 'N/A',
                    capital.created || 'N/A'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Business Contacts */}
        {business.contacts && business.contacts.length > 0 && (
          <View style={styles.section}>
            <Text style={styles.sectionTitle}>Business Contacts</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['Type', 'Value', 'Is Primary', 'In Contact', 'CA Contact', 'Created']}
                isHeader={true}
              />
              {safeMap(business.contacts, (contact, index) => (
                <TableRow
                  key={index}
                  cells={[
                    contact.contact_type || 'N/A',
                    contact.contact_value || 'N/A',
                    contact.is_primary ? 'Yes' : 'No',
                    contact.in_contact || 'N/A',
                    contact.ca_contact || 'N/A',
                    contact.created || 'N/A'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Publications */}
        {business.publications && business.publications.length > 0 && (
          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionTitle}>Publications</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['Date', 'Type', 'Status', 'Title', 'Description', 'Changes Summary']}
                isHeader={true}
              />
              {safeMap(business.publications, (pub, index) => (
                <TableRow
                  key={index}
                  cells={[
                    pub.publication_date || 'N/A',
                    pub.publication_type_display || pub.publication_type || 'N/A',
                    pub.publication_status_display || pub.publication_status || 'N/A',
                    pub.title || 'N/A',
                    pub.description || 'N/A',
                    pub.changes_summary || 'N/A'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Date of Acts */}
        {business.date_of_acts && business.date_of_acts.length > 0 && (
          <View style={styles.section} wrap={false}>
            <Text style={styles.sectionTitle}>Date of Acts</Text>
            <View style={styles.table}>
              <TableRow 
                cells={['Date', 'Type', 'Description', 'Status']}
                isHeader={true}
              />
              {safeMap(business.date_of_acts, (act, index) => (
                <TableRow
                  key={index}
                  cells={[
                    act.date_of_act ? new Date(act.date_of_act).toLocaleDateString() : 'N/A',
                    act.act_type_display || act.act_type || 'N/A',
                    act.description || 'N/A',
                    act.active_date_of_acts ? 'Active' : 'Inactive'
                  ]}
                />
              ))}
            </View>
          </View>
        )}

        {/* Security Section */}
        <View style={styles.securitySection} fixed>
          <View>
            <Text style={styles.securityText}>Document Security Information</Text>
            <Text style={styles.securityText}>{securityCode}</Text>
            <Text style={styles.securityText}>
              Generated: {new Date().toLocaleDateString()}
            </Text>
            <Text style={styles.securityText}>
              Verify authenticity at kseco.com/verify
            </Text>
          </View>
          <View style={styles.barcodeContainer}>
            <Image
              src={qrDataUrl}
              style={styles.qrCode}
            />
            <Text style={styles.barcodeText}>Scan to verify</Text>
          </View>
        </View>

        {/* Footer */}
        <View style={styles.footer} fixed>
          <Text style={styles.footerText}>
            This document is electronically generated and does not require a signature
          </Text>
          <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `Page ${pageNumber} of ${totalPages}`
          )} />
        </View>
      </Page>
    </Document>
  );
};

// Export functions
export const generatePDF = async (data) => {
  try {
    if (!data || !data.business) {
      throw new Error('Invalid data structure provided to PDF generator');
    }

    // Create a promise to handle the PDF generation
    return new Promise(async (resolve, reject) => {
      try {
        const pdfDoc = <CompanyRegisterPDF data={data} />;
        const asPdf = pdf([]);
        asPdf.updateContainer(pdfDoc);
        const blob = await asPdf.toBlob();
        
        // Create download link
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${data.business.business_name || 'business'}_details.pdf`;
        
        // Trigger download
        document.body.appendChild(link);
        link.click();
        
        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
        
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  } catch (error) {
    console.error('Error generating PDF:', error);
    throw error;
  }
};

export default CompanyRegisterPDF;