import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const TableComponent = ({ companies = [], simplified = false }) => {
    if (!Array.isArray(companies)) {
        console.warn('TableComponent: companies is not an array', companies);
        return null;
    }

    // Always take only the last 10 companies
    const displayCompanies = companies.slice(-10).reverse();

    if (displayCompanies.length === 0) {
        return (
            <div className="tw-text-center tw-py-4 tw-text-gray-500">
                No companies to display
            </div>
        );
    }

    const formatCapital = (capital) => {
        if (!capital || capital === "empty") return 'N/A';
        const num = parseFloat(capital);
        return num.toLocaleString('en-US', { 
            style: 'currency', 
            currency: 'EUR',
            minimumFractionDigits: 2
        });
    };

    const renderStatus = (status) => {
        const isRegistered = status?.toLowerCase().includes('regjistruar');
        
        return (
            <span className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium ${
                isRegistered 
                    ? 'tw-bg-green-100 tw-text-green-800' 
                    : 'tw-bg-red-100 tw-text-red-800'
            }`}>
                {isRegistered ? 'Regjistruar' : 'Pasiv'}
            </span>
        );
    };

    return (
        <div className="tw-overflow-x-auto">
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th scope="col">EMRI I BIZNESIT</th>
                        <th scope="col">NUMRI UNIK</th>
                        <th scope="col">KOMUNA</th>
                        <th scope="col">DATA E REGJISTRIMIT</th>
                        <th scope="col">STATUSI</th>
                        {!simplified && (
                            <>
                                <th scope="col">ADRESA</th>
                                <th scope="col">LLOJI I BIZNESIT</th>
                                <th scope="col">KAPITALI</th>
                                <th scope="col">NR. PUNËTORËVE</th>
                                <th scope="col">TELEFONI</th>
                                <th scope="col">EMAIL</th>
                                <th scope="col">WEBSITE</th>
                            </>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {displayCompanies.map((company, index) => (
                        <motion.tr
                            key={company.id || index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{
                                duration: 0.3,
                                delay: index * 0.1
                            }}
                        >
                            <td>{company.business_name || 'N/A'}</td>
                            <td>{company.unique_identifier || 'N/A'}</td>
                            <td>{company.municipality_name || company.municipality || 'N/A'}</td>
                            <td>{company.registration_date || 'N/A'}</td>
                            <td>{renderStatus(company.status_in_arbk)}</td>
                            {!simplified && (
                                <>
                                    <td>{company.address === 'empty' ? 'N/A' : company.address || 'N/A'}</td>
                                    <td>{company.business_type || 'N/A'}</td>
                                    <td>{formatCapital(company.capital)}</td>
                                    <td>{company.number_of_employees === 'empty' ? 'N/A' : company.number_of_employees || 'N/A'}</td>
                                    <td>{company.phone === 'empty' ? 'N/A' : company.phone || 'N/A'}</td>
                                    <td>
                                        {company.email === 'empty' ? (
                                            'N/A'
                                        ) : company.email ? (
                                            <a href={`mailto:${company.email}`} className="tw-text-blue-600 hover:tw-text-blue-800">
                                                {company.email}
                                            </a>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                    <td>
                                        {company.website === 'empty' ? (
                                            'N/A'
                                        ) : company.website ? (
                                            <a
                                                href={company.website.startsWith('http') ? company.website : `http://${company.website}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="tw-text-blue-600 hover:tw-text-blue-800"
                                            >
                                                {company.website}
                                            </a>
                                        ) : (
                                            'N/A'
                                        )}
                                    </td>
                                </>
                            )}
                        </motion.tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableComponent;
