import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faXmark, 
    faUser, 
    faBuilding, 
    faCalendar, 
    faMapMarkerAlt, 
    faPhone, 
    faEnvelope, 
    faMoneyBillWave,
    faUserTie,
    faIndustry,
    faIdCard,
    faUsers,
    faPercent,
    faCheckCircle,
    faGlobe
} from '@fortawesome/free-solid-svg-icons';

const InfoCard = ({ icon, label, value }) => (
    <div className="tw-bg-white tw-p-4 tw-rounded-xl tw-shadow-sm tw-border tw-border-gray-100 hover:tw-shadow-md tw-transition-shadow">
        <div className="tw-flex tw-items-center tw-gap-3">
            <div className="tw-flex-shrink-0 tw-w-9 tw-h-9 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-bg-[#438a5e]/10">
                <FontAwesomeIcon 
                    icon={icon} 
                    className="tw-w-4 tw-h-4 tw-text-[#438a5e]" 
                    style={{
                        width: '16px',
                        height: '16px',
                        display: 'block',
                        margin: 'auto'
                    }}
                    fixedWidth 
                />
            </div>
            <div className="tw-flex-1 tw-min-w-0">
                <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-mb-0.5">{label}</p>
                <p className="tw-text-sm tw-text-gray-600 tw-truncate">{value || 'N/A'}</p>
            </div>
        </div>
    </div>
);

const DetailSection = ({ title, children }) => (
    <div className="tw-space-y-3">
        <div className="tw-flex tw-items-center tw-gap-2">
            <h3 className="tw-text-lg tw-font-semibold tw-text-gray-900">{title}</h3>
        </div>
        <div className="tw-space-y-4">
            {children}
        </div>
    </div>
);

const BusinessDetailsModal = ({ isOpen, onClose, businessDetails }) => {
    if (!businessDetails) return null;
    const { business, owners, representatives, activities } = businessDetails;

    const modalVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: { type: "spring", damping: 25, stiffness: 300 }
        },
        exit: {
            opacity: 0,
            y: 20,
            transition: { duration: 0.2 }
        }
    };

    const overlayVariants = {
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
        exit: { opacity: 0 }
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <div className="tw-fixed tw-inset-0 tw-z-[9999] tw-overflow-y-auto">
                    <motion.div
                        className="tw-fixed tw-inset-0 tw-bg-black/60 tw-backdrop-blur-[2px]"
                        variants={overlayVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        onClick={onClose}
                    />
                    
                    <div className="tw-min-h-screen tw-px-4 tw-text-center tw-flex tw-items-center tw-justify-center">
                        <motion.div
                            className="tw-w-full tw-max-w-4xl tw-bg-white tw-rounded-2xl tw-shadow-2xl tw-relative tw-mx-auto tw-z-[10000]"
                            variants={modalVariants}
                            initial="hidden"
                            animate="visible"
                            exit="exit"
                        >
                            {/* Header */}
                            <div className="tw-relative tw-bg-gradient-to-r tw-from-[#438a5e] tw-to-[#5aa277] tw-px-8 tw-py-6 tw-rounded-t-2xl">
                                <button
                                    onClick={onClose}
                                    className="tw-absolute tw-right-6 tw-top-6 tw-p-2 tw-rounded-full tw-text-white hover:tw-bg-white/20 tw-transition-colors"
                                >
                                    <FontAwesomeIcon icon={faXmark} className="tw-w-5 tw-h-5" />
                                </button>
                                <h2 className="tw-text-2xl tw-font-bold tw-text-white">{business.business_name}</h2>
                                <div className="tw-flex tw-items-center tw-mt-2 tw-text-white/90 tw-text-sm">
                                    <FontAwesomeIcon icon={faIdCard} className="tw-w-4 tw-h-4 tw-mr-2" />
                                    <span>{business.unique_identifier}</span>
                                </div>
                            </div>

                            {/* Content */}
                            <div className="tw-max-h-[calc(100vh-200px)] tw-overflow-y-auto tw-p-8">
                                <div className="tw-space-y-8">
                                    {/* Main Info */}
                                    <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
                                        <InfoCard
                                            icon={faBuilding}
                                            label="Business Type"
                                            value={business.business_type}
                                        />
                                        <InfoCard
                                            icon={faCalendar}
                                            label="Registration Date"
                                            value={new Date(business.registration_date).toLocaleDateString()}
                                        />
                                        <InfoCard
                                            icon={faMapMarkerAlt}
                                            label="Location"
                                            value={business.municipality_rel?.name}
                                        />
                                        <InfoCard
                                            icon={faMoneyBillWave}
                                            label="Capital"
                                            value={business.capital}
                                        />
                                        <InfoCard
                                            icon={faUsers}
                                            label="Employees"
                                            value={business.number_of_employees}
                                        />
                                        <InfoCard
                                            icon={faCheckCircle}
                                            label="Status"
                                            value={business.status_in_arbk}
                                        />
                                    </div>

                                    {/* Contact Information */}
                                    <DetailSection title="Contact Information">
                                        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                                            <InfoCard
                                                icon={faPhone}
                                                label="Phone"
                                                value={business.phone}
                                            />
                                            <InfoCard
                                                icon={faEnvelope}
                                                label="Email"
                                                value={business.email}
                                            />
                                            {business.website && (
                                                <InfoCard
                                                    icon={faGlobe}
                                                    label="Website"
                                                    value={business.website}
                                                />
                                            )}
                                        </div>
                                    </DetailSection>

                                    {/* Owners */}
                                    {owners?.length > 0 && (
                                        <DetailSection title="Owners">
                                            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                                                {owners.map((owner, index) => (
                                                    <div key={index} className="tw-bg-gray-50 tw-p-4 tw-rounded-xl tw-border tw-border-gray-100">
                                                        <div className="tw-flex tw-items-center tw-gap-3">
                                                            <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-[#438a5e]/10">
                                                                <FontAwesomeIcon 
                                                                    icon={faUser} 
                                                                    className="tw-w-5 tw-h-5 tw-text-[#438a5e]"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        display: 'block',
                                                                        margin: 'auto'
                                                                    }}
                                                                    fixedWidth 
                                                                />
                                                            </div>
                                                            <div className="tw-flex-1 tw-min-w-0">
                                                                <p className="tw-font-medium tw-truncate">{owner.owner_name} {owner.owner_surname}</p>
                                                                <div className="tw-flex tw-items-center tw-gap-1 tw-text-sm tw-text-gray-600">
                                                                    <FontAwesomeIcon 
                                                                        icon={faPercent} 
                                                                        className="tw-w-3 tw-h-3"
                                                                        style={{
                                                                            width: '12px',
                                                                            height: '12px',
                                                                            display: 'block',
                                                                            margin: 'auto'
                                                                        }}
                                                                        fixedWidth 
                                                                    />
                                                                    <span>{owner.capital_percentage}% ownership</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </DetailSection>
                                    )}

                                    {/* Representatives */}
                                    {representatives?.length > 0 && (
                                        <DetailSection title="Representatives">
                                            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-4">
                                                {representatives.map((rep, index) => (
                                                    <div key={index} className="tw-bg-gray-50 tw-p-4 tw-rounded-xl tw-border tw-border-gray-100">
                                                        <div className="tw-flex tw-items-center tw-gap-3">
                                                            <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-[#438a5e]/10">
                                                                <FontAwesomeIcon 
                                                                    icon={faUserTie} 
                                                                    className="tw-w-5 tw-h-5 tw-text-[#438a5e]"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        display: 'block',
                                                                        margin: 'auto'
                                                                    }}
                                                                    fixedWidth 
                                                                />
                                                            </div>
                                                            <div className="tw-flex-1 tw-min-w-0">
                                                                <p className="tw-font-medium tw-truncate">{rep.representative_name} {rep.representative_surname}</p>
                                                                <p className="tw-text-sm tw-text-gray-600 tw-truncate">{rep.position || 'Position not specified'}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </DetailSection>
                                    )}

                                    {/* Activities */}
                                    {activities?.length > 0 && (
                                        <DetailSection title="Business Activities">
                                            <div className="tw-space-y-3">
                                                {activities.map((activity, index) => (
                                                    <div key={index} className="tw-bg-gray-50 tw-p-4 tw-rounded-xl tw-border tw-border-gray-100">
                                                        <div className="tw-flex tw-items-start tw-gap-3">
                                                            <div className="tw-flex-shrink-0 tw-w-10 tw-h-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-[#438a5e]/10">
                                                                <FontAwesomeIcon 
                                                                    icon={faIndustry} 
                                                                    className="tw-w-5 tw-h-5 tw-text-[#438a5e]"
                                                                    style={{
                                                                        width: '20px',
                                                                        height: '20px',
                                                                        display: 'block',
                                                                        margin: 'auto'
                                                                    }}
                                                                    fixedWidth 
                                                                />
                                                            </div>
                                                            <div className="tw-flex-1 tw-min-w-0">
                                                                <p className="tw-font-medium tw-truncate">{activity.activity_code}</p>
                                                                <p className="tw-text-sm tw-text-gray-600 tw-line-clamp-2">{activity.activity_description}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </DetailSection>
                                    )}
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </div>
            )}
        </AnimatePresence>
    );
};

export default BusinessDetailsModal;
