import React, { useEffect } from 'react';
import FilterAccordionSection from '../FilterAccordionSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faIndustry,
    faExclamationCircle,
    faBriefcase,
    faCoins,
    faUsers,
    faChevronDown,
    faCheck,
    faCalendar,
    faSearch,
    faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

const FilterSection = ({ onFilter, filterOptions, selectedFilters }) => {
    useEffect(() => {
        console.log('FilterSection received filterOptions:', filterOptions);
        console.log('FilterSection received selectedFilters:', selectedFilters);
    }, [filterOptions, selectedFilters]);

    const filterSections = [
        {
            title: 'Status',
            type: 'checkbox',
            options: [
                { value: 'Regjistruar', label: 'Regjistruar' },
                { value: 'Çregjistruar', label: 'Çregjistruar' }
            ],
            key: 'status_in_arbk',
            icon: faExclamationCircle,
            selectedValues: selectedFilters?.status_in_arbk || []
        },
        {
            title: 'Place',
            type: 'search',
            key: 'place',
            icon: faMapMarkerAlt,
            placeholder: 'Search for a city, region or country...',
            value: selectedFilters?.place || ''
        },
        {
            title: 'Address',
            type: 'search',
            key: 'address',
            icon: faMapMarkerAlt,
            placeholder: 'Search by street address...',
            value: selectedFilters?.address || ''
        },
        {
            title: 'Komuna',
            type: 'checkbox',
            options: filterOptions.municipalities?.map(opt => ({
                value: opt.value,
                label: opt.label
            })) || [],
            key: 'municipality',
            icon: faBuilding,
            selectedValues: selectedFilters?.municipality || []
        },
        {
            title: 'Gjinia',
            type: 'checkbox',
            options: filterOptions.genders?.map(opt => ({
                value: opt.value,
                label: opt.label
            })) || [],
            key: 'gender',
            icon: faUsers,
            selectedValues: selectedFilters?.gender || []
        },
        {
            title: 'Aktiviteti',
            type: 'checkbox',
            options: filterOptions.activities?.map(opt => ({
                value: opt.value,
                label: opt.label
            })) || [],
            key: 'activity_type',
            icon: faIndustry,
            selectedValues: selectedFilters?.activity_type || []
        },
        {
            title: 'Sektori',
            type: 'checkbox',
            options: filterOptions.sectors?.map(opt => ({
                value: opt.value,
                label: opt.label
            })) || [],
            key: 'sector',
            icon: faBriefcase,
            selectedValues: selectedFilters?.sector || []
        },
        {
            title: 'Lloji i Biznesit',
            type: 'checkbox',
            options: filterOptions.business_types?.map(opt => ({
                value: opt.value,
                label: opt.label
            })) || [],
            key: 'business_type',
            icon: faBuilding,
            selectedValues: selectedFilters?.business_type || []
        },
        {
            title: 'Numri Unik',
            type: 'search',
            key: 'unique_identifier',
            icon: faSearch,
            selectedValues: selectedFilters?.unique_identifier || []
        },
        {
            title: 'Data e Regjistrimit',
            type: 'date',
            key: 'registration_date',
            icon: faCalendar,
            selectedValues: selectedFilters?.registration_date || []
        },
        {
            title: 'Publication Time',
            type: 'checkbox',
            options: [
                { value: 'today', label: 'Today' },
                { value: 'yesterday', label: 'Yesterday' },
                { value: 'last_week', label: 'Last Week' },
                { value: 'last_year', label: 'Last Year' },
                { value: 'all_time', label: 'All Time' }
            ],
            key: 'publication_timeframe',
            icon: faCalendar,
            selectedValues: selectedFilters?.publication_timeframe || []
        },
        {
            title: 'Publication Date',
            type: 'date',
            key: 'publication_date',
            icon: faCalendar,
            value: selectedFilters?.publication_date || ''
        }
    ];

    return (
        <div className="tw-space-y-4">
            {filterSections.map((section) => (
                <FilterAccordionSection
                    key={section.key}
                    title={section.title}
                    options={section.options}
                    selectedValues={section.selectedValues}
                    onChange={(values) => onFilter(section.key, values)}
                    type={section.type}
                    icon={section.icon}
                    placeholder={section.placeholder}
                    value={section.value}
                />
            ))}
        </div>
    );
};

export default FilterSection;