import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Sheet } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import Header from '../components/Header';
import Footer from '../components/Footer';

import Pagination from '../components/services/Pagination';

import SearchBar from '../components/services/searchBar';
import FilterSection from '../components/services/FilterSection';
import SortDropdown from '../components/services/SortDropdown';
import BusinessTable from '../components/services/BusinessTable';

import { getBusinesses, searchBusinesses, getFilterOptions } from '../api';
import { filterOptions as fetchFilterOptionsAPI } from '../api/filters';
import api from '../api';  // Import the api instance

const Services = () => {
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sortBy, setSortBy] = useState('-id');
  const [searchTerm, setSearchTerm] = useState('');
  const [filterOptions, setFilterOptions] = useState({
    municipalities: [],
    activities: [],
    sectors: [], 
    business_types: [], 
    status_in_arbk: [], 
    unique_identifier: [], 
    registration_date: [],
    genders: [], // Added genders to filter options
  });
  const [selectedFilters, setSelectedFilters] = useState({
    municipality: [],
    activity_type: [],
    status_in_arbk: [],
    business_type: [],
    unique_identifier: [],
    sector: [], 
    place: [],
    gender: [],
    address: '',
    publication_timeframe: [],
    publication_date: '',
  });
  const itemsPerPage = 10; 

  // Fetch filter options on mount
  useEffect(() => {
    const fetchFilterOptions = async () => {
      try {
        const data = await fetchFilterOptionsAPI();
        console.log('API Response:', data);  
        
        const formattedOptions = {
          municipalities: data.municipalities || [], // Don't reformat, use as is from backend
          activities: data.activities || [], // Activities are already formatted by the backend
          sectors: data.sectors?.map(item => ({
            value: item.sector_id,
            label: item.name
          })) || [],
          business_types: data.business_types?.map(item => ({
            value: item,
            label: item
          })) || [],
          status_in_arbk: data.status_in_arbk?.map(item => ({
            value: item,
            label: item
          })) || [],
          unique_identifier: [],
          registration_date: [],
          genders: data.genders?.map(item => ({
            value: item,
            label: item === 'Male' ? 'Mashkull' : item === 'Female' ? 'Femër' : 'Unknown'
          })) || []
        };
        
        console.log('Formatted Options:', formattedOptions);  
        setFilterOptions(formattedOptions);
      } catch (error) {
        console.error('Error fetching filter options:', error);
      }
    };
    fetchFilterOptions();
  }, []);

  // Fetch businesses when filters, sort, or page changes
  useEffect(() => {
    const fetchBusinesses = async () => {
      try {
        setLoading(true);
        
        // Only include non-empty filters
        const nonEmptyFilters = Object.fromEntries(
          Object.entries(selectedFilters).filter(([key, value]) => {
            if (key === 'unique_identifier' || key === 'place' || key === 'address' || key === 'publication_date') {
              return typeof value !== '' && value !== null;
            }
            if (Array.isArray(value)) {
              return value.length > 0;
            }
            return value !== '';
          })
        );
        
        // Prepare the query parameters
        const queryParams = {
          page: currentPage,
          per_page: itemsPerPage,
          sort_by: sortBy,
          ...nonEmptyFilters
        };

        if (searchTerm) {
          queryParams.search = searchTerm;
        }

        // Fetch businesses with filters
        const data = await getBusinesses(queryParams);
        setBusinesses(data.results || []); 
        setTotalPages(data.total_pages || 1);
        
      } catch (err) {
        setError('Failed to fetch businesses');
        console.error('Error:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchBusinesses();
  }, [currentPage, selectedFilters, sortBy, searchTerm]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleSortChange = (newSortBy) => {
    setSortBy(newSortBy);
    setCurrentPage(1);
  };

  const handleFilterChange = (category, values) => {
    setCurrentPage(1);
    setSelectedFilters(prevFilters => {
      const newFilters = { ...prevFilters };
      
      if (category === 'unique_identifier' || category === 'place' || category === 'address' || category === 'publication_date') {
        // Handle search filters
        if (values && values[0]) {
          newFilters[category] = values[0];
        } else {
          delete newFilters[category];
        }
      } else if (category === 'registration_date') {
        // Handle date range filter
        if (values.length > 0) {
          newFilters[category] = values;
        } else {
          delete newFilters[category];
        }
      } else {
        // Handle checkbox filters
        if (values.length > 0) {
          newFilters[category] = values;
        } else {
          delete newFilters[category];
        }
      }

      return newFilters;
    });
  };

  const handleFilterSubmit = async (filters) => {
    try {
      setLoading(true);
      const response = await axios.post('/api/filter-by-checkbox-services/', {
        ...filters,
        page: currentPage,
        page_size: itemsPerPage
      });
      setBusinesses(response.data.results);
      setTotalPages(Math.ceil(response.data.count / itemsPerPage));
      setLoading(false);
    } catch (error) {
      console.error('Error applying filters:', error);
      setLoading(false);
    }
  };

  const handleExportExcel = async () => {
    try {
      setLoading(true);
      const response = await api.get('/api/export-businesses-excel/', {
        responseType: 'blob',
        headers: {
          'Accept': 'text/csv'
        },
        params: {
          ...selectedFilters,
          search: searchTerm,
          sort_by: sortBy
        }
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `businesses_${new Date().toISOString().split('T')[0]}.csv`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error exporting to Excel:', error);
    } finally {
      setLoading(false);
    }
  };

  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(false);

  const toggleMobileFilter = () => {
    setIsMobileFilterOpen(!isMobileFilterOpen);
    document.body.style.overflow = !isMobileFilterOpen ? 'hidden' : 'auto';
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <>
      <Header />

      <div className="tw-bg-white">
        <main className="tw-mx-auto tw-max-w-7xl tw-px-4 sm:tw-px-6 lg:tw-px-8">
          {/* Page Header - Visible on all screen sizes */}
          <div className="tw-flex tw-items-baseline tw-justify-between tw-border-b tw-border-gray-200 tw-pb-6 tw-pt-24">
            <h1 className="tw-text-4xl tw-font-bold tw-tracking-tight tw-text-[#438a5e]">KSeco<span className='tw-text-[#357048]'>KS</span></h1>

            {/* Desktop Search and Actions */}
            <div className="tw-hidden md:tw-flex md:tw-flex-1 md:tw-items-center md:tw-justify-end tw-gap-4">
              <div className="tw-flex tw-items-center tw-gap-4">
                <div className="tw-flex-1 tw-max-w-lg">
                  <SearchBar onSearch={handleSearch} />
                </div>
                <button
                  onClick={handleExportExcel}
                  className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-px-4 tw-py-2 tw-bg-[#438a5e] tw-border-0 tw-rounded-lg tw-cursor-pointer hover:tw-bg-[#2f6c4a] tw-transition-colors tw-min-w-[140px]"
                  title="Export to Excel"
                >
                  <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
                    <FontAwesomeIcon 
                      icon={faFileExcel}
                      className="pricing-icon fa-fw tw-text-white"
                      style={{
                        width: '20px',
                        height: '20px',
                        display: 'block',
                        margin: 'auto'
                      }}
                    />
                    <span className="tw-text-white tw-font-medium">Export Excel</span>
                  </div>
                </button>
                <SortDropdown
                  sortBy={sortBy}
                  onSortChange={handleSortChange}
                />
              </div>
            </div>

            {/* Mobile Search Toggle */}
            <button 
              className="tw-md:hidden tw-p-2 tw-rounded-lg hover:tw-bg-gray-100"
              onClick={toggleMobileFilter}
            >
              <i className="fas fa-filter tw-text-[#438a5e] tw-text-xl"></i>
            </button>
          </div>

          {/* Mobile Search and Actions - Only visible on mobile */}
          <div className="md:tw-hidden tw-py-4">
            <div className="tw-flex tw-items-center tw-gap-4">
              <div className="tw-flex-1">
                <SearchBar onSearch={handleSearch} />
              </div>
              <button
                onClick={handleExportExcel}
                className="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-px-4 tw-py-2 tw-bg-[#438a5e] tw-border-0 tw-rounded-lg tw-cursor-pointer hover:tw-bg-[#2f6c4a] tw-transition-colors tw-min-w-[140px]"
                title="Export to Excel"
              >
                <div className="tw-flex tw-items-center tw-justify-center tw-gap-2">
                  <FontAwesomeIcon 
                    icon={faFileExcel}
                    className="pricing-icon fa-fw tw-text-white"
                    style={{
                      width: '20px',
                      height: '20px',
                      display: 'block',
                      margin: 'auto'
                    }}
                  />
                  <span className="tw-text-white tw-font-medium">Export Excel</span>
                </div>
              </button>
            </div>
            <div className="tw-flex tw-items-center tw-justify-end tw-mt-4">
              <SortDropdown
                sortBy={sortBy}
                onSortChange={handleSortChange}
              />
            </div>
          </div>

          <section aria-labelledby="products-heading" className="tw-pb-24 tw-pt-6">
            <h2 id="products-heading" className="tw-sr-only">
              Products
            </h2>

            <div className="tw-grid tw-grid-cols-1 tw-gap-x-8 tw-gap-y-10 lg:tw-grid-cols-4">
              {/* Filters - Desktop */}
              <div className="tw-hidden lg:tw-block">
                <FilterSection 
                  onFilter={handleFilterChange}
                  filterOptions={filterOptions}
                  selectedFilters={selectedFilters}
                />
              </div>

              {/* Mobile Filter Overlay */}
              {isMobileFilterOpen && (
                <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-z-40 lg:tw-hidden" onClick={toggleMobileFilter}></div>
              )}

              {/* Mobile Filter Sidebar */}
              <div className={`tw-fixed tw-inset-y-0 tw-right-0 tw-z-50 tw-w-full tw-bg-white tw-px-4 tw-py-6 sm:tw-max-w-sm sm:tw-ring-1 sm:tw-ring-gray-900/10 tw-transform tw-transition-transform lg:tw-hidden ${isMobileFilterOpen ? 'tw-translate-x-0' : 'tw-translate-x-full'}`}>
                <div className="tw-flex tw-items-center tw-justify-between tw-mb-4">
                  <h2 className="tw-text-lg tw-font-semibold tw-text-gray-900">Filters</h2>
                  <button
                    onClick={toggleMobileFilter}
                    className="tw-rounded-md tw-text-gray-400 hover:tw-text-gray-500 focus:tw-outline-none"
                  >
                    <i className="fas fa-times tw-text-xl"></i>
                  </button>
                </div>
                <div className="tw-mt-4">
                  <FilterSection 
                    onFilter={handleFilterChange}
                    filterOptions={filterOptions}
                    selectedFilters={selectedFilters}
                  />
                </div>
              </div>

              {/* Business list */}
              <div className="lg:tw-col-span-3">
                {loading ? (
                  <div className="tw-flex tw-justify-center tw-items-center tw-h-64">
                    <div className="tw-animate-spin tw-rounded-full tw-h-32 tw-w-32 tw-border-b-2 tw-border-gray-900"></div>
                  </div>
                ) : (
                  <div>
                    <BusinessTable businesses={businesses} />
                    {businesses.length > 0 && (
                      <div className="tw-mt-6 tw-flex tw-justify-center">
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
        </main>
      </div>
      <Footer />
    </>
  );
};

export default Services;