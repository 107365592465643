// src/api/filterByCheckboxServices.js
import axios from 'axios';

const API_BASE_URL = 'https://backend.kseco.ch/api'; // Update with your backend URL

export const filterByCheckboxServices = async (filterParams) => {
    try {
        console.log('Sending params to backend:', filterParams);

        const response = await axios.get(`${API_BASE_URL}/filter-by-checkbox-services/`, {
            params: filterParams
        });

        console.log('Response from backend:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error filtering by checkbox services:', error);
        throw error;
    }
};
