// src/api/filters.js
import api from './index';

export const filterOptions = async () => {
    try {
        const response = await api.get('/api/filter-options/');
        return response.data;
    } catch (error) {
        console.error('Error fetching filter options:', error);
        throw error;
    }
};

export const filterByCapitalRange = async (capitalRange) => {
    try {
        const response = await api.get(`/api/filter-by-capital/?capital_range=${capitalRange}`);
        return response.data;
    } catch (error) {
        console.error('Error filtering by capital range:', error);
        throw error;
    }
};

export const filterByCheckbox = async (filterParams) => {
    try {
        const response = await api.get('/api/filter-by-checkbox/', { params: filterParams });
        return response.data;
    } catch (error) {
        console.error('Error filtering by checkbox:', error);
        throw error;
    }
};

export const filterByMap = async (municipality) => {
    try {
        const response = await api.get(`/api/filter-by-map/?municipality=${municipality}`);
        return response.data;
    } catch (error) {
        console.error('Error filtering by map:', error);
        throw error;
    }
};