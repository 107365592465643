import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getBusinessDetails, getOwnerBusinesses, getBusinessOwners } from '../api';
import Header from '../components/Header';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { Network } from 'vis-network/standalone';
import '../styles/businessDetails.css';
import { generatePDF } from '../utils/pdfGenerator';

const BusinessDetails = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [networkData, setNetworkData] = useState(null);
  const networkRef = useRef(null);
  const [businessDetails, setBusinessDetails] = useState({
    name: '',
    type: '',
    uniqueId: '',
    registrationDate: '',
    employeeCount: 0,
    phone: '',
    capital: '',
    status: '',
    In: 0,
    active: true,
    sectors: [],
    location: {
      municipality: '',
      place: '',
      address: '',
      addressType: '',
      inAddress: '',
      latitude: null,
      longitude: null,
      municipalityData: null,
      placeData: null
    },
    authorizedRepresentative: [],
    owners: [],
    activities: [],
    publications: [],
    date_of_acts: [],
    units: [],
    villages: [],
    addresses: [],
    capitals: [],
    contacts: []
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBusinessDetails = async () => {
      try {
        setLoading(true);
        const response = await getBusinessDetails(id);
        console.log('Business details response:', response);
        
        // Extract unique sectors from activities
        const uniqueSectors = [...new Set(response.activities.map(activity => activity.sector_name))];
        
        const data = {
          name: response.business.business_name,
          type: response.business.business_type,
          uniqueId: response.business.unique_identifier,
          registrationDate: response.business.registration_date,
          employeeCount: response.business.number_of_employees,
          phone: response.business.phone,
          capital: response.business.capital,
          status: response.business.status_in_arbk,
          In: response.business.In,
          active: response.business.active_data_business,
          sectors: uniqueSectors,
          location: {
            municipality: response.business.municipality_name || response.business.municipality,
            place: response.business.place_name || response.business.location_name,
            address: response.business.business_addresses[0]?.street_address || 'N/A',
            addressType: response.business.business_addresses[0]?.address_type_display || 'N/A',
            inAddress: response.business.business_addresses[0]?.in_address !== undefined ? 
                      response.business.business_addresses[0].in_address : 'N/A',
            latitude: response.business.latitude,
            longitude: response.business.longitude,
            municipalityData: response.business.related_municipality_data ? {
              in: response.business.related_municipality_data.In,
              ref: response.business.related_municipality_data.ref_municipality,
              name: response.business.related_municipality_data.municipality_name,
              active: response.business.related_municipality_data.active_data_municipality
            } : null,
            placeData: response.business.related_place_data ? {
              in: response.business.related_place_data.In,
              ref: response.business.related_place_data.ref_place,
              name: response.business.related_place_data.place_name,
              active: response.business.related_place_data.active_data_place,
              municipality: response.business.related_place_data.municipality
            } : null
          },
          authorizedRepresentative: response.representatives.map(rep => ({
            In: rep.in_representative,
            firstName: rep.representative_name,
            lastName: rep.representative_surname,
            position: rep.position,
            authorizations: rep.authorizations,
            representative_details: rep.representative_details
          })),
          owners: response.owners.map(owner => ({
            id: owner.id,
            name: owner.name,
            in_owner: owner.in_owner || 0,
            mo_owner: owner.mo_owner || 0,
            ca_owner: owner.ca_owner || null,
            capitalInEuros: owner.capital_in_euros || 0,
            capitalPercentage: owner.capital_percentage || 0,
            has_other_business: owner.has_other_business || false
          })),
          activities: response.activities.map(activity => ({
            code: activity.activity_code,
            description: activity.activity_description,
            type: activity.activity_list_rel?.activity || 'N/A',
            sector: activity.sector_name || 'N/A',
            in: activity.in_activity || 0,
            ca: activity.ca_activity || null,
            ca_activity: activity.ca_activity || null
          })),
          publications: response.business.publications.map(pub => ({
            ...pub,
            in_publication: pub.In || 0,
            ref_publication: pub.ref_publication || 1
          })),
          date_of_acts: response.business.date_of_acts.map(act => ({
            ...act,
            in_date_of_acts: act.In || 0,
            ref_date_of_acts: act.ref_date_of_acts || 1
          })),
          business_addresses: response.business.business_addresses.map(addr => ({
            ...addr,
            in_address: addr.in_address || 0,
            ca_address: addr.ca_address || null
          })),
          business_capitals: response.business.business_capitals.map(cap => ({
            ...cap,
            in_capital: cap.in_capital || 0,
            ca_capital: cap.ca_capital || null,
            ref_capital: cap.ref_capital || null
          }))
        };
        
        setBusinessDetails(data);
        setError(null);
        
        // Initialize network with main business node
        const networkData = {
          nodes: [
            {
              id: 'main_business',
              shape: 'image',
              image: 'https://cdn-icons-png.flaticon.com/512/1324/1324872.png',
              label: data.name,
              type: 'business',
              size: 42,
              font: {
                color: '#111',
                size: 15,
                strokeWidth: 0.5,
                strokeColor: '#111'
              }
            },
            ...data.owners.map((owner) => ({
              id: `owner_${owner.id}`,
              shape: 'image',
              image: 'https://cdn-icons-png.flaticon.com/512/3024/3024605.png',
              label: owner.name,
              type: 'owner',
              ownerId: owner.id,
              size: 42,
              font: {
                color: '#111',
                size: 15,
                strokeWidth: 0.5,
                strokeColor: '#111'
              }
            }))
          ],
          edges: data.owners.map((owner) => ({
            id: `edge_main_${owner.id}`,
            from: 'main_business',
            to: `owner_${owner.id}`,
            color: {
              color: '#333',
              highlight: '#000'
            },
            width: 2
          }))
        };
        
        setNetworkData(networkData);
        
      } catch (err) {
        console.error('Error fetching business details:', err);
        setError('Failed to load business details');
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchBusinessDetails();
    }
  }, [id]);

  // Function to fetch owner's related businesses
  const fetchOwnerBusinesses = async (ownerId) => {
    if (!ownerId) {
      console.error('Owner ID is undefined');
      return null;
    }
    try {
      const data = await getOwnerBusinesses(ownerId, id);
      console.log('Owner businesses data:', data);
      return data;
    } catch (error) {
      console.error('Error fetching owner businesses:', error);
      return null;
    }
  };

  const findExistingOwnerNode = (ownerName, ownerSurname) => {
    if (!networkRef.current) return null;
    
    const nodes = networkRef.current.body.data.nodes.get();
    const fullName = `${ownerName} ${ownerSurname}`;
    
    return nodes.find(node => 
      node.type === 'owner' && 
      node.label === fullName
    );
  };

  const addBusinessOwnersToNetwork = async (businessNode) => {
    if (!networkRef.current) {
      console.log('No network instance');
      return;
    }

    try {
      const businessId = businessNode.id.replace('business_', '');
      console.log('Fetching owners for business:', businessId);
      
      const ownersData = await getBusinessOwners(businessId);
      console.log('Business owners data:', ownersData);

      if (!ownersData.has_owners) {
        alert('This business has no other owners.');
        return;
      }

      const newNodes = [];
      const newEdges = [];
      const existingOwners = [];

      ownersData.owners.forEach((owner) => {
        // Check if owner already exists in network
        const existingOwner = findExistingOwnerNode(owner.name, owner.surname);
        const ownerNodeId = existingOwner ? existingOwner.id : `owner_${owner.id}`;
        
        if (existingOwner) {
          console.log('Found existing owner node:', existingOwner.label);
          existingOwners.push(existingOwner);
        } else {
          // Add new owner node
          console.log('Adding new owner node:', owner.full_name);
          networkRef.current.body.data.nodes.add({
            id: ownerNodeId,
            shape: 'image',
            image: 'https://cdn-icons-png.flaticon.com/512/3024/3024605.png',
            label: owner.full_name,
            type: 'owner',
            ownerId: owner.id,
            size: 42,
            font: {
              color: '#111',
              size: 15,
              strokeWidth: 0.5,
              strokeColor: '#111'
            }
          });
          newNodes.push(ownerNodeId);
        }

        // Add edge regardless if owner is new or existing
        const edgeId = `${businessNode.id}_${ownerNodeId}`;
        if (!networkRef.current.body.data.edges.get(edgeId)) {
          console.log('Adding new edge:', edgeId);
          networkRef.current.body.data.edges.add({
            id: edgeId,
            from: businessNode.id,
            to: ownerNodeId,
            color: {
              color: '#333',
              highlight: '#000'
            },
            width: 2
          });
          newEdges.push(edgeId);
        }
      });

      if (newNodes.length > 0 || newEdges.length > 0) {
        console.log('Added nodes:', newNodes.length, 'edges:', newEdges.length);
        if (existingOwners.length > 0) {
          console.log('Found existing owners:', existingOwners.length);
        }
        
        // Update network data state
        setNetworkData(prev => ({
          nodes: [...prev.nodes, ...newNodes],
          edges: [...prev.edges, ...newEdges]
        }));

        // Give the network time to process the new nodes and edges
        setTimeout(() => {
          try {
            networkRef.current.fit({
              animation: {
                duration: 1000,
                easingFunction: 'easeInOutQuad'
              }
            });
          } catch (error) {
            console.error('Error fitting network:', error);
          }
        }, 500);

        const message = [];
        if (newNodes.length > 0) {
          message.push(`Found ${newNodes.length} new owner(s)`);
        }
        if (existingOwners.length > 0) {
          message.push(`Connected ${existingOwners.length} existing owner(s)`);
        }
        alert(message.join(' and ') + ' for this business!');
      }
    } catch (error) {
      console.error('Error adding business owners to network:', error);
    }
  };

  useEffect(() => {
    const container = document.getElementById('graph');
    if (!container || !businessDetails) return;

    // Initialize network data
    const data = {
      nodes: [
        {
          id: 1,
          shape: 'image',
          image: 'https://cdn-icons-png.flaticon.com/512/1324/1324872.png',
          label: businessDetails.name || '',
          type: 'business',
          size: 42,
          font: {
            color: '#111',
            size: 15,
            strokeWidth: 0.5,
            strokeColor: '#111'
          }
        },
        ...(businessDetails.owners || []).map((owner, index) => ({
          id: index + 2,
          shape: 'image',
          image: 'https://cdn-icons-png.flaticon.com/512/3024/3024605.png',
          label: owner.name,
          type: 'owner',
          ownerId: owner.id,
          size: 42,
          font: {
            color: '#111',
            size: 15,
            strokeWidth: 0.5,
            strokeColor: '#111'
          }
        }))
      ],
      edges: (businessDetails.owners || []).map((_, index) => ({
        id: `edge_${1}_${index + 2}`,
        from: 1,
        to: index + 2,
        color: {
          color: '#333',
          highlight: '#000'
        },
        width: 2
      }))
    };

    const options = {
      nodes: {
        borderWidth: 0,
        color: {
          border: '#222',
          background: 'transparent'
        },
        shape: 'image'
      },
      edges: {
        smooth: {
          type: 'continuous'
        }
      },
      physics: {
        barnesHut: {
          gravitationalConstant: -2000,
          centralGravity: 0.3,
          springLength: 200,
          springConstant: 0.04
        },
        stabilization: {
          enabled: true,
          iterations: 1000,
          updateInterval: 100
        }
      },
      interaction: {
        hover: true,
        tooltipDelay: 200,
        zoomView: true,
        dragView: true
      }
    };

    const network = new Network(container, data, options);
    networkRef.current = network;
    setNetworkData(data);

    network.on('click', async function(params) {
      if (params.nodes.length > 0) {
        const nodeId = params.nodes[0];
        const clickedNode = this.body.data.nodes.get(nodeId);
        
        if (clickedNode && clickedNode.type === 'owner') {
          console.log('Clicked owner node:', clickedNode);
          
          // Show loading state
          this.body.data.nodes.update({
            id: clickedNode.id,
            color: { background: '#f0f0f0' }
          });

          try {
            const ownerBusinesses = await fetchOwnerBusinesses(clickedNode.ownerId);
            console.log('Fetched owner businesses:', ownerBusinesses);

            if (ownerBusinesses && ownerBusinesses.related_businesses.length > 0) {
              addRelatedBusinessesToNetwork(clickedNode, ownerBusinesses.related_businesses);
              alert(`Found ${ownerBusinesses.related_businesses.length} related business(es) for this owner!`);
            } else {
              alert('This owner has no other businesses.');
            }
          } catch (error) {
            console.error('Error fetching owner businesses:', error);
          } finally {
            // Reset node appearance
            this.body.data.nodes.update({
              id: clickedNode.id,
              color: { background: 'transparent' }
            });
          }
        } else if (clickedNode && clickedNode.type === 'business' && nodeId !== 1) {
          // Instead of navigating, fetch and display other owners
          console.log('Clicked business node:', clickedNode);
          await addBusinessOwnersToNetwork(clickedNode);
        }
      }
    });

    // Initial fit
    network.once('stabilized', () => {
      network.fit({
        animation: {
          duration: 1000,
          easingFunction: 'easeInOutQuad'
        }
      });
    });

    return () => {
      if (networkRef.current) {
        networkRef.current.destroy();
        networkRef.current = null;
      }
    };
  }, [businessDetails]);

  const addRelatedBusinessesToNetwork = (ownerNode, relatedBusinesses) => {
    if (!networkRef.current || !relatedBusinesses || relatedBusinesses.length === 0) {
      console.log('No related businesses to add');
      return;
    }

    console.log('Adding related businesses:', relatedBusinesses);
    const newNodes = [];
    const newEdges = [];

    relatedBusinesses.forEach(business => {
      const businessNodeId = `business_${business.id}`;
      
      // Check if node already exists
      if (!networkRef.current.body.data.nodes.get(businessNodeId)) {
        console.log('Adding new business node:', business.business_name);
        networkRef.current.body.data.nodes.add({
          id: businessNodeId,
          shape: 'image',
          image: 'https://cdn-icons-png.flaticon.com/512/1324/1324872.png',
          label: business.business_name,
          type: 'business',
          size: 42,
          font: {
            color: '#111',
            size: 15,
            strokeWidth: 0.5,
            strokeColor: '#111'
          }
        });
        newNodes.push(businessNodeId);
      }

      const edgeId = `${ownerNode.id}_${businessNodeId}`;
      if (!networkRef.current.body.data.edges.get(edgeId)) {
        console.log('Adding new edge:', edgeId);
        networkRef.current.body.data.edges.add({
          id: edgeId,
          from: ownerNode.id,
          to: businessNodeId,
          color: {
            color: '#333',
            highlight: '#000'
          },
          width: 2
        });
        newEdges.push(edgeId);
      }
    });

    // Update the network
    if (newNodes.length > 0 || newEdges.length > 0) {
      console.log('Added nodes:', newNodes.length, 'edges:', newEdges.length);
      
      // Update network data state
      setNetworkData(prev => ({
        nodes: [...prev.nodes, ...newNodes],
        edges: [...prev.edges, ...newEdges]
      }));

      // Give the network time to process the new nodes and edges
      setTimeout(() => {
        try {
          networkRef.current.fit({
            animation: {
              duration: 1000,
              easingFunction: 'easeInOutQuad'
            }
          });
        } catch (error) {
          console.error('Error fitting network:', error);
        }
      }, 500);
    }
  };

  const handleDownloadPDF = async () => {
    try {
      setLoading(true);
      
      // Prepare the data structure
      const pdfData = {
        business: {
          ...businessDetails,
          publications: businessDetails.publications || [],
          date_of_acts: businessDetails.date_of_acts || [],
          business_addresses: businessDetails.addresses || [],
          business_contacts: businessDetails.contacts || [],
          business_capitals: businessDetails.capitals || [],
          business_name: businessDetails.name,
          business_type: businessDetails.type,
          unique_identifier: businessDetails.uniqueId,
          registration_date: businessDetails.registrationDate,
          number_of_employees: businessDetails.employeeCount,
          status_in_arbk: businessDetails.status,
          municipality_name: businessDetails.location?.municipality,
          place_name: businessDetails.location?.place,
          address: businessDetails.location?.address
        },
        representatives: businessDetails.authorizedRepresentative || [],
        owners: businessDetails.owners || [],
        activities: businessDetails.activities || []
      };
      
      await generatePDF(pdfData);
      setLoading(false);
      
    } catch (error) {
      console.error('Error downloading PDF:', error);
      setLoading(false);
      // Show error message to user
      setError('Failed to generate PDF. Please try again.');
    }
  };

  const renderDownloadButton = () => (
    <button 
      onClick={handleDownloadPDF}
      className="download-pdf-button"
      disabled={loading}
    >
      {loading ? (
        <span>Generating PDF...</span>
      ) : (
        <>
          <i className="fas fa-file-pdf"></i>
          <span>Download PDF</span>
        </>
      )}
    </button>
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  if (!businessDetails) {
    return null;
  }

  return (
    <>
      <Header />
      <motion.div 
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="business-details"
      >
        <div className="business-details-container">
          <div className="business-header">
            <div className="header-content">
              <div className="business-icon">
                <i className="fas fa-building"></i>
              </div>
              <div className="business-info">
                <div className="title-row">
                  <h1>{businessDetails.name}</h1>
                  <div className="header-actions">
                    <div className="status-badge">
                      <span className={`status-indicator ${businessDetails.active ? 'active' : 'inactive'}`}></span>
                      {businessDetails.status || 'N/A'}
                    </div>
                    {renderDownloadButton()}
                  </div>
                </div>
                <div className="business-details-grid">
                  <div className="detail-card">
                    <i className="fas fa-tag"></i>
                    <div className="detail-content">
                      <span className="detail-label">Business Type</span>
                      <span className="detail-value">{businessDetails.type || 'N/A'}</span>
                    </div>
                  </div>
                  <div className="detail-card highlight">
                    <i className="fas fa-fingerprint"></i>
                    <div className="detail-content">
                      <span className="detail-label">In</span>
                      <span className="detail-value">{businessDetails?.In !== undefined ? businessDetails.In : 'N/A'}</span>
                    </div>
                  </div>
                  <div className="detail-card">
                    <i className="fas fa-id-card"></i>
                    <div className="detail-content">
                      <span className="detail-label">ID</span>
                      <span className="detail-value">{businessDetails.uniqueId || 'N/A'}</span>
                    </div>
                  </div>
                  <div className="detail-card">
                    <i className="fas fa-calendar"></i>
                    <div className="detail-content">
                      <span className="detail-label">Registration Date</span>
                      <span className="detail-value">{businessDetails.registrationDate || 'N/A'}</span>
                    </div>
                  </div>
                  <div className="detail-card">
                    <i className="fas fa-users"></i>
                    <div className="detail-content">
                      <span className="detail-label">Employees</span>
                      <span className="detail-value">{businessDetails.employeeCount || '0'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="content-section">
            <div className="container">
              <div className="row">
                <motion.div 
                  className="col-md-12"
                  initial={{ y: -20 }}
                  animate={{ y: 0 }}
                  transition={{ duration: 0.6, ease: "easeOut" }}
                >
                  <div className="location-card">
                    <div className="location-header">
                      <h2>Vendndodhja e biznesit</h2>
                    </div>
                    <div className="location-content">
                      <div className="location-info">
                        <div className="info-item">
                          <i className="fas fa-city"></i>
                          <div>
                            <h3>Komuna</h3>
                            <p>{businessDetails?.location?.municipalityData?.name || 'N/A'}</p>
                            <div className="meta-container">
                              <small className="meta-info">
                                <span className="meta-label">In:</span>
                                <span className="meta-value">{businessDetails?.location?.municipalityData?.in !== undefined ? businessDetails.location.municipalityData.in : 'N/A'}</span>
                              </small>
                              <small className="meta-info">
                                <span className="meta-label">Ref:</span>
                                <span className="meta-value">{businessDetails?.location?.municipalityData?.ref !== undefined ? businessDetails.location.municipalityData.ref : 'N/A'}</span>
                              </small>
                              {businessDetails?.location?.municipalityData?.active !== undefined && (
                                <small className="meta-info">
                                  <span className="meta-label">Active:</span>
                                  <span className={`meta-value ${businessDetails.location.municipalityData.active ? 'active' : 'inactive'}`}>
                                    {businessDetails.location.municipalityData.active ? 'Yes' : 'No'}
                                  </span>
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="info-item">
                          <i className="fas fa-map-marker-alt"></i>
                          <div>
                            <h3>Vendi</h3>
                            <p>{businessDetails?.location?.placeData?.name || 'N/A'}</p>
                            <div className="meta-container">
                              <small className="meta-info">
                                <span className="meta-label">In:</span>
                                <span className="meta-value">{businessDetails?.location?.placeData?.in !== undefined ? businessDetails.location.placeData.in : 'N/A'}</span>
                              </small>
                              <small className="meta-info">
                                <span className="meta-label">Ref:</span>
                                <span className="meta-value">{businessDetails?.location?.placeData?.ref !== undefined ? businessDetails.location.placeData.ref : 'N/A'}</span>
                              </small>
                              {businessDetails?.location?.placeData?.active !== undefined && (
                                <small className="meta-info">
                                  <span className="meta-label">Active:</span>
                                  <span className={`meta-value ${businessDetails.location.placeData.active ? 'active' : 'inactive'}`}>
                                    {businessDetails.location.placeData.active ? 'Yes' : 'No'}
                                  </span>
                                </small>
                              )}

                            </div>
                          </div>
                        </div>
                        <div className="info-item">
                          <i className="fas fa-home"></i>
                          <div>
                            <h3>Adresa</h3>
                            <p>{businessDetails?.location?.address || 'N/A'}</p>
                            <div className="meta-container">
                              <small className="meta-info">
                                <span className="meta-label">Type:</span>
                                <span className="meta-value">{businessDetails?.location?.addressType || 'N/A'}</span>
                              </small>
                              <small className="meta-info">
                                <span className="meta-label">In:</span>
                                <span className="meta-value">{businessDetails?.location?.inAddress !== undefined ? businessDetails.location.inAddress : 'N/A'}</span>
                              </small>
                              {businessDetails?.location?.latitude && (
                                <small className="meta-info">
                                  <span className="meta-label">Lat:</span>
                                  <span className="meta-value">{businessDetails.location.latitude}</span>
                                </small>
                              )}
                              {businessDetails?.location?.longitude && (
                                <small className="meta-info">
                                  <span className="meta-label">Long:</span>
                                  <span className="meta-value">{businessDetails.location.longitude}</span>
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                {[
                  { icon: "briefcase", label: "Emri tregtar", value: businessDetails.name },
                  { icon: "flag", label: "Lloji i biznesit", value: businessDetails.type },
                  { icon: "info", label: "Numri unik identifikues", value: businessDetails.uniqueId },
                  { icon: "calendar", label: "Data e regjistrimit", value: businessDetails.registrationDate },
                  { icon: "person", label: "Numri i puntoreve", value: businessDetails.employeeCount },
                  { icon: "phone", label: "Telefoni", value: businessDetails.phone },
                  { 
                    icon: "coins", 
                    label: "Kapitali", 
                    value: businessDetails.business_capitals?.[0] ? (
                      <div className="capital-details">
                        <div className="capital-main">
                          {`${businessDetails.business_capitals[0].amount} ${businessDetails.business_capitals[0].currency}`}
                        </div>
                        <div className="capital-extra">
                          <div className="capital-item">
                            <span className="capital-label">In Capital:</span>
                            <span className="capital-value">
                              {businessDetails.business_capitals[0].in_capital !== null ? 
                                businessDetails.business_capitals[0].in_capital : 'N/A'}
                            </span>
                          </div>
                          <div className="capital-item">
                            <span className="capital-label">CA Capital:</span>
                            <span className="capital-value">
                              {businessDetails.business_capitals[0].ca_capital !== null ? 
                                businessDetails.business_capitals[0].ca_capital : 'N/A'}
                            </span>
                          </div>
                          <div className="capital-item">
                            <span className="capital-label">Ref Capital:</span>
                            <span className="capital-value">
                              {businessDetails.business_capitals[0].ref_capital !== null ? 
                                businessDetails.business_capitals[0].ref_capital : 'N/A'}
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : 'N/A'
                  },
                  { 
                    icon: "pen", 
                    label: "Statusi ne ARBK", 
                    value: businessDetails.status,
                    className: businessDetails.status?.toLowerCase().includes('aktiv') ? 'status-active' : 'status-inactive'
                  },
                  { icon: "industry", label: "Sektori", value: businessDetails.sectors.join(', ') }
                ].map((item, index) => (
                  <motion.div 
                    key={index}
                    className="col-md-4"
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div className="emri">
                          <p><i className={`fa-solid fa-${item.icon}`}></i>&nbsp;{item.label}</p>
                        </div>
                        <div>
                          <p className={item.className}>{item.value || 'N/A'}</p>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            <div className="container">
              <div className="row">
                <motion.div 
                  className="col-md-12 tb"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                >
                  <p><i className="fa-solid fa-pen"></i>&nbsp;Pronaret</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">In</th>
                        <th scope="col">Mo</th>
                        <th scope="col">Ca</th>
                        <th scope="col">Ref</th>
                        <th scope="col">Emri</th>
                        <th scope="col">Kapitali ne Euro</th>
                        <th scope="col">Perqindja e kapitalit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessDetails.owners.map((owner, index) => (
                        <tr key={index}>
                          <td>{owner.in_owner}</td>
                          <td>{owner.mo_owner}</td>
                          <td>{owner.ca_owner}</td>
                          <td>{owner.ref}</td>
                          <td>{owner.name}</td>
                          <td>{owner.capitalInEuros}</td>
                          <td>{owner.capitalPercentage}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </motion.div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <motion.div 
                  className="col-md-12 tb"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.4 }}
                >
                  <div className="tb">
                    <p>
                      <i className="fa-solid fa-user"></i>&nbsp; Aktivitetet
                    </p>
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">In</th>
                          <th scope="col">Ca</th>
                          <th scope="col">Ca Activity</th>
                          <th scope="col">Kodi</th>
                          <th scope="col">Pershkrimi</th>
                          <th scope="col">Lloji</th>
                          <th scope="col">Sektori</th>
                        </tr>
                      </thead>
                      <tbody>
                        {businessDetails.activities.map((activity, index) => (
                          <tr key={index}>
                            <td>{activity.in}</td>
                            <td>{activity.ca}</td>
                            <td>{activity.ca_activity}</td>
                            <td>{activity.code}</td>
                            <td>{activity.description}</td>
                            <td>{activity.type}</td>
                            <td>{activity.sector}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </motion.div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <motion.div 
                  className="col-md-12 tb"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.5 }}
                >
                  <p><i className="fa-solid fa-industry"></i>&nbsp;Perfaqesuesit</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">In</th>
                        <th scope="col">Emri</th>
                        <th scope="col">Mbiemri</th>
                        <th scope="col">Pozita</th>
                        <th scope="col">Detajet</th>
                        <th scope="col">Autorizimet</th>
                      </tr>
                    </thead>
                    <tbody>
                      {businessDetails.authorizedRepresentative.map((rep, index) => (
                        <tr key={index}>
                          <td>{rep.In}</td>
                          <td>{rep.firstName}</td>
                          <td>{rep.lastName}</td>
                          <td>{rep.position}</td>
                          <td>{rep.representative_details || 'N/A'}</td>
                          <td>{rep.authorizations || 'N/A'}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </motion.div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <motion.div 
                  className="col-md-12 tb"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 }}
                >
                  <div className="tb">
                    <p>
                      <i className="fas fa-file-alt"></i>
                      Publikimet
                    </p>
                    {businessDetails.publications && businessDetails.publications.length > 0 ? (
                      <div className="tw-overflow-x-auto">
                        <table className="publications-table">
                          <thead>
                            <tr>
                              <th>In</th>
                              <th>Ref</th>
                              <th>Data e Publikimit</th>
                              <th>Lloji</th>
                              <th>Titulli</th>
                              <th>Përshkrimi</th>
                              <th>Statusi</th>
                            </tr>
                          </thead>
                          <tbody>
                            {businessDetails.publications.map((publication, index) => (
                              <tr key={publication.id || index}>
                                <td>{publication.in_publication}</td>
                                <td>{publication.ref_publication}</td>
                                <td>{new Date(publication.publication_date).toLocaleDateString('sq-AL')}</td>
                                <td>{publication.publication_type_display}</td>
                                <td>{publication.title}</td>
                                <td className="description-cell">{publication.description || 'N/A'}</td>
                                <td>
                                  <span className={`publication-status ${publication.publication_status.toLowerCase()}`}>
                                    {publication.publication_status_display}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="tw-text-gray-500">Nuk ka publikime për këtë biznes.</p>
                    )}
                  </div>
                  <div className="tb">
                    <p>
                      <i className="fas fa-calendar-alt"></i>
                      Data e Akteve
                    </p>
                    {businessDetails.date_of_acts && businessDetails.date_of_acts.length > 0 ? (
                      <div className="tw-overflow-x-auto">
                        <table className="date-of-acts-table">
                          <thead>
                            <tr>
                              <th>In</th>
                              <th>Ref</th>
                              <th>Data e Aktit</th>
                              <th>Lloji i Aktit</th>
                              <th>Përshkrimi</th>
                              <th>Statusi</th>
                            </tr>
                          </thead>
                          <tbody>
                            {businessDetails.date_of_acts.map((act, index) => (
                              <tr key={act.id || index}>
                                <td>{act.in_date_of_acts}</td>
                                <td>{act.ref_date_of_acts}</td>
                                <td>{new Date(act.date_of_act).toLocaleDateString('sq-AL')}</td>
                                <td>{act.act_type_display}</td>
                                <td className="description-cell">{act.description || 'N/A'}</td>
                                <td>
                                  <span className={`act-status ${act.active_date_of_acts ? 'active' : 'inactive'}`}>
                                    {act.active_date_of_acts ? 'Aktiv' : 'Joaktiv'}
                                  </span>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <p className="tw-text-gray-500">Nuk ka akte për këtë biznes.</p>
                    )}
                  </div>
                </motion.div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <motion.div 
                  className="col-md-12 tb"
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.6, delay: 0.6 }}
                >
                  <div id="graph" style={{ height: '400px', width: '100%', marginTop: '20px', border: '1px solid #eee', borderRadius: '8px' }}></div>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      <Footer />
    </>
  );
};

export default BusinessDetails;