import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBuilding,
    faIndustry,
    faExclamationCircle,
    faBriefcase,
    faCoins,
    faUsers,
    faChevronDown,
    faCheck,
    faCalendar
} from '@fortawesome/free-solid-svg-icons';

const FilterAccordionSection = ({ title, options, selectedValues = [], onChange, type = 'checkbox' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Calculate the number of active filters
    const activeFilterCount = Array.isArray(selectedValues) ? selectedValues.length : (selectedValues ? 1 : 0);

    const handleCheckboxChange = (value) => {
        let newSelected;
        const optionValue = typeof value === 'object' ? value.value : value;
        if (selectedValues.includes(optionValue)) {
            newSelected = selectedValues.filter(item => item !== optionValue);
        } else {
            newSelected = [...selectedValues, optionValue];
        }
        onChange(newSelected);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchValue(value);
        onChange([value]);
    };

    const handleDateChange = (dateType, value) => {
        if (dateType === 'start') {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
        onChange([startDate, endDate].filter(Boolean));
    };

    const getIcon = () => {
        let icon;
        switch(title) {
            case 'Municipality':
                icon = faBuilding;
                break;
            case 'Industry':
                icon = faIndustry;
                break;
            case 'Status':
                icon = faExclamationCircle;
                break;
            case 'Business Type':
                icon = faBriefcase;
                break;
            case 'Capital Range':
                icon = faCoins;
                break;
            case 'Owner Gender':
                icon = faUsers;
                break;
            case 'Registration Date':
                icon = faCalendar;
                break;
            default:
                icon = faBuilding;
        }
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-w-6 tw-h-6">
                <FontAwesomeIcon 
                    icon={icon} 
                    className="tw-text-[#438a5e] tw-w-5 tw-h-5"
                />
            </div>
        );
    };

    const renderContent = () => {
        if (type === 'search') {
            return (
                <input
                    type="text"
                    value={searchValue}
                    onChange={handleSearchChange}
                    placeholder={title === 'Place' ? 'Search for a city, region or country...' : 'Search unique identifier...'}
                    className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm"
                />
            );
        } else if (type === 'date') {
            return (
                <div className="tw-space-y-2">
                    <div className="tw-flex tw-flex-col">
                        <label className="tw-text-sm tw-text-gray-600 tw-mb-1">Start Date:</label>
                        <input
                            type="date"
                            value={startDate}
                            onChange={(e) => handleDateChange('start', e.target.value)}
                            className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm"
                        />
                    </div>
                    <div className="tw-flex tw-flex-col">
                        <label className="tw-text-sm tw-text-gray-600 tw-mb-1">End Date:</label>
                        <input
                            type="date"
                            value={endDate}
                            onChange={(e) => handleDateChange('end', e.target.value)}
                            className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-sm"
                        />
                    </div>
                </div>
            );
        } else {
            return (
                <div className="tw-space-y-2 tw-max-h-60 tw-overflow-y-auto">
                    {options?.map((option) => {
                        const optionValue = typeof option === 'object' ? option.value : option;
                        const optionLabel = typeof option === 'object' ? option.label : option;
                        return (
                            <label
                                key={optionValue}
                                className={`tw-flex tw-items-start tw-px-2 tw-py-1.5 tw-rounded-md hover:tw-bg-[#438a5e]/5 tw-cursor-pointer tw-group tw-transition-colors ${
                                    title === 'Industry' || title === 'Aktiviteti' ? 'tw-flex-col' : 'tw-items-center'
                                }`}
                            >
                                <div className={`tw-flex tw-items-center ${title === 'Industry' || title === 'Aktiviteti' ? 'tw-w-full' : ''}`}>
                                    <div className="tw-relative tw-flex tw-items-center">
                                        <input
                                            type="checkbox"
                                            className="tw-peer tw-sr-only"
                                            checked={selectedValues?.includes(optionValue)}
                                            onChange={() => handleCheckboxChange(option)}
                                        />
                                        <div className={`
                                            tw-w-4 tw-h-4 tw-rounded 
                                            tw-border tw-transition-colors
                                            ${selectedValues?.includes(optionValue)
                                                ? 'tw-bg-[#438a5e] tw-border-[#438a5e]'
                                                : 'tw-border-gray-300 group-hover:tw-border-[#438a5e]'
                                            }
                                            tw-flex tw-items-center tw-justify-center
                                        `}>
                                            {selectedValues?.includes(optionValue) && (
                                                <FontAwesomeIcon 
                                                    icon={faCheck}
                                                    className="tw-text-white tw-text-xs"
                                                />
                                            )}
                                        </div>
                                    </div>
                                    {(title === 'Industry' || title === 'Aktiviteti') ? (
                                        <div className="tw-ml-2 tw-flex tw-flex-col">
                                            <span className="tw-font-medium tw-text-gray-900">{optionValue}</span>
                                            <span className="tw-text-sm tw-text-gray-500 tw-mt-0.5">{optionLabel}</span>
                                        </div>
                                    ) : (
                                        <span className="tw-ml-2 tw-text-gray-700">
                                            {optionLabel}
                                        </span>
                                    )}
                                </div>
                            </label>
                        );
                    })}
                </div>
            );
        }
    };

    return (
        <div className="tw-mb-3 tw-overflow-hidden tw-bg-white tw-rounded-lg tw-border tw-border-gray-200">
            <button 
                onClick={() => setIsOpen(!isOpen)}
                className="tw-w-full tw-flex tw-items-center tw-justify-between tw-px-4 tw-py-3 tw-bg-gray-50 hover:tw-bg-gray-100 tw-transition-all tw-cursor-pointer"
            >
                <div className="tw-flex tw-items-center tw-justify-between tw-w-full">
                    <div className="tw-flex tw-items-center tw-gap-2">
                        {getIcon()}
                        <span className="tw-text-sm tw-font-medium">{title}</span>
                        {activeFilterCount > 0 && (
                            <span className="tw-inline-flex tw-items-center tw-justify-center tw-w-6 tw-h-6 tw-text-sm tw-font-semibold tw-text-blue-800 tw-bg-blue-100 tw-rounded-full">
                                {activeFilterCount}
                            </span>
                        )}
                    </div>
                    <FontAwesomeIcon
                        icon={faChevronDown}
                        className={`tw-w-4 tw-h-4 tw-transition-transform ${isOpen ? 'tw-rotate-180' : ''}`}
                    />
                </div>
            </button>
            {isOpen && (
                <div className="tw-p-4">
                    {renderContent()}
                </div>
            )}
        </div>
    );
};

export default FilterAccordionSection;
