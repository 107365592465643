import axios from 'axios';
import api, { setAuthToken } from './index';

const API_URL = process.env.REACT_APP_API_URL || 'https://backend.kseco.ch/';

// Create axios instance with default config
const axiosInstance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add interceptor to add token to requests
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add interceptor to refresh token on 401 errors
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const refreshToken = localStorage.getItem('refresh_token');
        const response = await axios.post(`${API_URL}/auth/token/refresh/`, {
          refresh: refreshToken,
        });

        const { access } = response.data;
        setAuthToken(access);

        originalRequest.headers.Authorization = `Bearer ${access}`;
        return axiosInstance(originalRequest);
      } catch (err) {
        // If refresh token fails, logout user
        setAuthToken(null);
        localStorage.removeItem('refresh_token');
        window.location.href = '/';
        return Promise.reject(err);
      }
    }

    return Promise.reject(error);
  }
);

const authAPI = {
  // Register user
  register: async (userData) => {
    try {
      const response = await api.post('/auth/register/', userData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Login user
  login: async (email, password) => {
    try {
      const response = await api.post('/auth/login/', {
        email: email,
        password: password
      });
      
      if (response.data.access && response.data.refresh) {
        // Store tokens
        localStorage.setItem('refresh_token', response.data.refresh);
        setAuthToken(response.data.access);
        return response.data;
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      if (error.response && error.response.data) {
        throw error.response.data;
      }
      throw { detail: 'Login failed. Please check your credentials.' };
    }
  },

  // Logout user
  logout: async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (refreshToken) {
        await api.post('/auth/logout/', {
          refresh_token: refreshToken
        });
      }
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      // Clear tokens and headers
      setAuthToken(null);
    }
  },

  // Get user details
  getUserDetails: async () => {
    try {
      const token = localStorage.getItem('access_token');
      if (!token) {
        throw new Error('No access token found');
      }
      
      const response = await api.get('/auth/user/');
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error;
    }
  },

  // Check if user is authenticated
  isAuthenticated: () => {
    const token = localStorage.getItem('access_token');
    return !!token;
  },

  // Refresh token
  refreshToken: async () => {
    try {
      const refreshToken = localStorage.getItem('refresh_token');
      if (!refreshToken) {
        throw new Error('No refresh token available');
      }

      const response = await api.post('/auth/token/refresh/', {
        refresh: refreshToken
      });

      if (response.data.access) {
        setAuthToken(response.data.access);
        return response.data.access;
      }
    } catch (error) {
      setAuthToken(null);
      throw error;
    }
  }
};

export default authAPI;
