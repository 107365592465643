import React, { useState, useEffect } from 'react';
import { Filter } from 'lucide-react';
import { getLatestBusinesses } from '../api/latestBusinesses';
import { filterByCheckboxServices } from '../api/filterByCheckBoxServices';
import TableComponent from './TableComponent';
import FilterAccordionSection from './FilterAccordionSection';

const FormComponent = ({ 
    municipalities = [], 
    activities = [], 
    statuses = [],
    businessNames = [],
    businessTypes = [],
    sectors = [],
    uniqueIds = [],
    registrationDates = [],
    genders = [],
    onSubmit 
}) => {
    const [selectedFilters, setSelectedFilters] = useState({
        municipality: [],
        activity_type: [], 
        sector: [],
        business_type: [],
        status_in_arbk: [],
        unique_identifier: [],
        registration_date: [],
        gender: []
    });

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    useEffect(() => {
        fetchLatestBusinesses();
    }, []);

    const fetchLatestBusinesses = async () => {
        try {
            const data = await getLatestBusinesses();
            setTableData(data.businesses || data || []);
            setLoading(false);
        } catch (err) {
            setError('Failed to load latest businesses');
            setLoading(false);
        }
    };

    const handleFilterChange = async (filterId, values) => {
        // Convert values to strings if they are objects
        const processedValues = filterId === 'municipality' || filterId === 'sector'
            ? values.map(v => typeof v === 'object' ? v.value : v)
            : values;

        const newFilters = {
            ...selectedFilters,
            [filterId]: filterId === 'unique_identifier' ? processedValues[0] || '' : 
                       filterId === 'registration_date' ? processedValues : processedValues
        };
        setSelectedFilters(newFilters);

        // Only include non-empty filters
        const nonEmptyFilters = Object.fromEntries(
            Object.entries(newFilters).filter(([key, value]) => {
                if (key === 'unique_identifier') {
                    return value && value !== '';  // Check if string is not empty
                }
                if (key === 'registration_date') {
                    return Array.isArray(value) && value.length > 0;  // Check if date range is not empty
                }
                return Array.isArray(value) && value.length > 0;  // Check if array is not empty
            })
        );

        if (Object.keys(nonEmptyFilters).length > 0) {
            setLoading(true);
            try {
                // Convert arrays to proper format for axios.get
                const params = {};
                Object.entries(nonEmptyFilters).forEach(([key, value]) => {
                    if (key === 'unique_identifier') {
                        params[key] = value;  // Single value for unique_identifier
                    } else if (key === 'registration_date') {
                        params['registration_date_start'] = value[0];  // Start date
                        params['registration_date_end'] = value[1];    // End date
                    } else {
                        params[`${key}[]`] = value;  // Array for other filters
                    }
                });
                
                console.log('Sending params:', params);  // Debug log
                const response = await filterByCheckboxServices(params);
                // Take only the last 10 companies from the filtered results
                const last10Companies = (response.businesses || []).slice(-10).reverse();
                setTableData(last10Companies);
            } catch (err) {
                setError('Failed to apply filters');
                console.error('Filter error:', err);
            } finally {
                setLoading(false);
            }
        } else {
            fetchLatestBusinesses();
        }
    };

    const filterSections = [
        {
            id: 'municipality',
            title: 'Komuna',
            options: municipalities
        },
        {
            id: 'activity_type',
            title: 'Aktiviteti',
            className: 'tw-max-h-[400px]',
            options: activities
        },
        {
            id: 'sector',
            title: 'Sektori',
            options: sectors
        },
        {
            id: 'unique_identifier',
            title: 'Numri Unik',
            type: 'search',
            options: []
        },
        {
            id: 'business_type',
            title: 'Lloji i Biznesit',
            options: businessTypes
        },
        {
            id: 'status_in_arbk',
            title: 'Status',
            type: 'checkbox',
            options: [
                { value: 'Regjistruar', label: 'Regjistruar' },
                { value: 'Çregjistruar', label: 'Çregjistruar' }
            ]
        },
        {
            id: 'registration_date',
            title: 'Data e Regjistrimit',
            type: 'date',
            options: []
        },
        {
            id: 'gender',
            title: 'Gjinia e Pronarit',
            options: genders
        }
    ];

    return (
        <div className="tw-w-full tw-max-w-7xl tw-mx-auto tw-p-4 tw-space-y-6">
            <div className="tw-bg-white tw-rounded-lg tw-shadow-sm tw-p-4 tw-mb-6">
                <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
                    <div className="tw-flex tw-items-center tw-gap-2">
                        <button
                            onClick={() => setIsFilterOpen(!isFilterOpen)}
                            className="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-gray-700 tw-bg-white tw-border tw-border-gray-300 tw-rounded-lg hover:tw-bg-gray-50"
                        >
                            <Filter className="tw-w-4 tw-h-4" />
                            Filters
                        </button>
                        {/* Active Filter Badges */}
                        <div className="tw-flex tw-flex-wrap tw-gap-2">
                            {Object.entries(selectedFilters).map(([key, values]) => {
                                if (!values || (Array.isArray(values) && values.length === 0)) return null;
                                
                                const getLabel = () => {
                                    switch(key) {
                                        case 'municipality':
                                            return 'Municipality';
                                        case 'activity_type':
                                            return 'Activity';
                                        case 'sector':
                                            return 'Sector';
                                        case 'business_type':
                                            return 'Business Type';
                                        case 'status_in_arbk':
                                            return 'Status';
                                        case 'gender':
                                            return 'Gender';
                                        default:
                                            return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                                    }
                                };

                                const count = Array.isArray(values) ? values.length : 1;
                                
                                return (
                                    <span 
                                        key={key}
                                        className="tw-inline-flex tw-items-center tw-gap-1 tw-px-3 tw-py-1 tw-text-sm tw-font-medium tw-text-blue-800 tw-bg-blue-100 tw-rounded-full"
                                    >
                                        {getLabel()}: {count}
                                    </span>
                                );
                            })}
                        </div>
                    </div>
                </div>
                
                {isFilterOpen && (
                    <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-[300px,1fr] tw-gap-6">
                        <div className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-p-6 tw-sticky tw-top-4">
                            <h3 className="tw-text-lg tw-font-semibold tw-text-gray-900 tw-mb-4">Filters</h3>
                            <div className="tw-space-y-6">
                                {filterSections.map((section) => (
                                    <FilterAccordionSection
                                        key={section.id}
                                        title={section.title}
                                        options={section.options}
                                        type={section.type}
                                        selectedValues={selectedFilters[section.id]}
                                        onChange={(values) => handleFilterChange(section.id, values)}
                                        className={section.className}
                                    />
                                ))}
                            </div>
                        </div>

                        <div className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-p-6">
                            {loading ? (
                                <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-64 tw-space-y-4">
                                    <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-4 tw-border-primary-light tw-border-t-primary"></div>
                                    <p className="tw-text-gray-500">Loading results...</p>
                                </div>
                            ) : error ? (
                                <div className="tw-bg-red-50 tw-text-red-600 tw-p-4 tw-rounded-lg tw-text-center">
                                    <p className="tw-font-medium">{error}</p>
                                    <button 
                                        onClick={fetchLatestBusinesses}
                                        className="tw-mt-2 tw-text-sm tw-text-red-700 hover:tw-text-red-800 tw-underline"
                                    >
                                        Try again
                                    </button>
                                </div>
                            ) : (
                                <TableComponent 
                                    companies={tableData} 
                                    simplified={true}
                                />
                            )}
                        </div>
                    </div>
                )}
            </div>

            <div className="tw-bg-gradient-to-r tw-from-[#438a5e] tw-to-[#5aa277] tw-rounded-xl tw-shadow-lg">
                <div className="tw-p-6 tw-relative tw-overflow-hidden">
                    {/* Decorative circles */}
                    <div className="tw-absolute tw-right-0 tw-top-0 tw-w-40 tw-h-40 tw-opacity-10">
                        <div className="tw-absolute tw-right-0 tw-top-0 tw-w-40 tw-h-40 tw-border-4 tw-border-white tw-rounded-full"></div>
                        <div className="tw-absolute tw-right-10 tw-top-10 tw-w-32 tw-h-32 tw-border-4 tw-border-white tw-rounded-full"></div>
                    </div>

                    {/* Header content */}
                    <div className="tw-flex tw-flex-col md:tw-flex-row tw-justify-between tw-items-start md:tw-items-center tw-gap-4">
                        <div className="tw-flex tw-flex-col tw-gap-2">
                            <h2 className="tw-text-2xl tw-font-bold tw-text-white">
                                Search Results
                            </h2>
                            <div className="tw-flex tw-flex-wrap tw-gap-2 tw-items-center">
                                {/* Results count badge */}
                                <span className="tw-inline-flex tw-items-center tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium tw-bg-white/20 tw-text-white">
                                    {tableData.length} results
                                </span>

                                {/* Active filter badges */}
                                {Object.entries(selectedFilters).map(([key, values]) => {
                                    if (!values || (Array.isArray(values) && values.length === 0)) return null;
                                    
                                    const getLabel = () => {
                                        switch(key) {
                                            case 'municipality':
                                                return 'Municipality';
                                            case 'activity_type':
                                                return 'Activity';
                                            case 'sector':
                                                return 'Sector';
                                            case 'business_type':
                                                return 'Business Type';
                                            case 'status_in_arbk':
                                                return 'Status';
                                            case 'gender':
                                                return 'Gender';
                                            default:
                                                return key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                                        }
                                    };

                                    // For single values (like unique_identifier) or arrays
                                    const getDisplayValue = () => {
                                        if (Array.isArray(values)) {
                                            return `${values.length} selected`;
                                        }
                                        return values ? '1 selected' : '';
                                    };

                                    return (
                                        <span 
                                            key={key}
                                            className="tw-inline-flex tw-items-center tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium tw-bg-white/20 tw-text-white"
                                        >
                                            {getLabel()}: {getDisplayValue()}
                                        </span>
                                    );
                                })}
                            </div>
                        </div>

                        <div className="tw-flex tw-items-center tw-gap-4">
                            <button
                                onClick={() => setIsFilterOpen(!isFilterOpen)}
                                className="tw-flex tw-items-center tw-gap-2 tw-px-4 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-bg-white/10 tw-rounded-lg hover:tw-bg-white/20 tw-transition-colors"
                            >
                                <Filter className="tw-w-4 tw-h-4" />
                                {isFilterOpen ? 'Hide Filters' : 'Show Filters'}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="tw-grid tw-grid-cols-1 lg:tw-grid-cols-[300px,1fr] tw-gap-6">
                <div className={`tw-transition-all tw-duration-300 tw-ease-in-out ${isFilterOpen ? 'tw-block' : 'tw-hidden lg:tw-block'}`}>
                    <div className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-p-6 tw-sticky tw-top-4">
                        <h3 className="tw-text-lg tw-font-semibold tw-text-gray-900 tw-mb-4">Filters</h3>
                        <div className="tw-space-y-6">
                            {filterSections.map((section) => (
                                <FilterAccordionSection
                                    key={section.id}
                                    title={section.title}
                                    options={section.options}
                                    type={section.type}
                                    selectedValues={selectedFilters[section.id]}
                                    onChange={(values) => handleFilterChange(section.id, values)}
                                    className={section.className}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                <div className="tw-bg-white tw-rounded-xl tw-shadow-lg tw-p-6">
                    {loading ? (
                        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-h-64 tw-space-y-4">
                            <div className="tw-animate-spin tw-rounded-full tw-h-12 tw-w-12 tw-border-4 tw-border-primary-light tw-border-t-primary"></div>
                            <p className="tw-text-gray-500">Loading results...</p>
                        </div>
                    ) : error ? (
                        <div className="tw-bg-red-50 tw-text-red-600 tw-p-4 tw-rounded-lg tw-text-center">
                            <p className="tw-font-medium">{error}</p>
                            <button 
                                onClick={fetchLatestBusinesses}
                                className="tw-mt-2 tw-text-sm tw-text-red-700 hover:tw-text-red-800 tw-underline"
                            >
                                Try again
                            </button>
                        </div>
                    ) : (
                        <TableComponent 
                            companies={tableData} 
                            simplified={true}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default FormComponent;