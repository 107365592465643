// Banner.jsx
import React, { useState } from 'react';
import map from "../assets/images/ks.png";
import axios from 'axios';
import CountUp from 'react-countup';
import { useAuth } from '../context/AuthContext';
import { motion } from 'framer-motion';

const StatCard = ({ value, label, delay }) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ 
            scale: 1.05,
            transition: { duration: 0.2, ease: "easeOut" }
        }}
        transition={{ duration: 0.5, delay }}
        className="tw-bg-white/95 tw-rounded-xl tw-p-3 sm:tw-p-4 lg:tw-p-6 tw-shadow-lg tw-text-center tw-transform tw-transition-all tw-duration-300 hover:tw-shadow-2xl hover:tw-bg-white"
    >
        <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5, delay: delay + 0.2 }}
            className="tw-text-2xl sm:tw-text-3xl lg:tw-text-4xl tw-font-bold tw-text-hazel-green tw-mb-1 sm:tw-mb-2"
        >
            <CountUp 
                end={value} 
                duration={2} 
                separator="," 
            />
        </motion.div>
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: delay + 0.4 }}
            className="tw-text-gray-600 tw-mt-1 sm:tw-mt-2 tw-text-sm sm:tw-text-base lg:tw-text-lg tw-font-medium"
        >
            {label}
        </motion.div>
    </motion.div>
);

const Banner = ({ statisticsData, onSearchResults }) => {
    const { isAuthenticated } = useAuth();
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearch = async (e) => {
        e.preventDefault();
        
        try {
            const response = await axios.get(`https://backend.kseco.ch/api/search-business/`, {
                params: {
                    search_query: searchQuery
                }
            });

            const data = response.data;
            console.log("Search response:", data);
            
            if (data && data.businesses && data.businesses.length > 0) {
                onSearchResults(data.businesses);
            } else {
                onSearchResults([]);
                alert('No businesses found matching your search.');
            }
        } catch (error) {
            console.error('Search error:', error);
            alert('Error searching for businesses. Please try again.');
        }
    };

    const stats = {
        numberOfCompanies: statisticsData?.number_of_companies || 0,
        numberOfActiveCompanies: statisticsData?.number_of_active_companies || 0,
        numberOfBankruptCompanies: statisticsData?.number_of_bankrupt_companies || 0,
        todayPublications: statisticsData?.today_publications || 0
    };

    return (
        <div className="banner-area auto-height text-color inc-shape baneri relative overflow-hidden">
            <div className="container">
                <div className="row align-items-center">
                    {/* Left side - Search Section */}
                    <div className="col-lg-6 col-md-12">
                        <div className="tw-bg-white tw-p-3 md:tw-p-6 tw-rounded-lg tw-shadow-lg">
                            <h3 className="tw-text-gray-800 tw-font-semibold tw-text-lg md:tw-text-2xl tw-mb-3 md:tw-mb-4 tw-text-center md:tw-text-left">
                                Search Companies
                            </h3>
                            <div className="tw-space-y-3 md:tw-space-y-4">
                                {/* Search Input Container */}
                                <div className="tw-flex tw-flex-col md:tw-flex-row tw-border tw-border-gray-200 tw-rounded-lg md:tw-rounded-md tw-overflow-hidden">
                                    {/* Search Form */}
                                    <form onSubmit={handleSearch} className="tw-flex tw-grow tw-flex-col md:tw-flex-row">
                                        {/* Input Wrapper */}
                                        <div className="tw-flex tw-items-center tw-bg-white tw-px-3 tw-py-2 md:tw-flex-1">
                                            <span className="fa fa-search tw-text-hazel-green tw-text-lg" aria-hidden="true"></span>
                                            <input
                                                type="text"
                                                className="tw-flex-1 tw-ml-2 tw-bg-transparent tw-border-none tw-text-gray-800 tw-py-1.5 md:tw-py-2 focus:tw-outline-none focus:tw-ring-0 tw-text-base"
                                                value={searchQuery}
                                                onChange={(e) => setSearchQuery(e.target.value)}
                                                placeholder="Search companies..."
                                                aria-label="Search companies"
                                            />
                                        </div>
                                        
                                        {/* Search Button */}
                                        <button
                                            type="submit"
                                            className="tw-w-full md:tw-w-auto tw-bg-hazel-green tw-text-white tw-font-medium md:tw-font-semibold tw-py-2.5 tw-px-4 hover:tw-bg-green-goose tw-transition tw-duration-300 tw-text-base md:tw-rounded-r-md"
                                        >
                                            Search
                                        </button>
                                    </form>
                                </div>

                                {/* Advanced Filters Button */}
                                <div className="tw-flex tw-justify-center md:tw-justify-start">
                                    <button 
                                        className="tw-w-full md:tw-w-auto tw-bg-hazel-green md:tw-bg-transparent tw-border tw-border-hazel-green tw-text-white md:tw-text-hazel-green tw-py-2 tw-px-4 tw-rounded-md tw-transition tw-duration-300 hover:tw-bg-green-goose md:hover:tw-bg-hazel-green hover:tw-text-white tw-flex tw-items-center tw-justify-center tw-gap-2"
                                    >
                                        <i className="fa-solid fa-filter"></i>
                                        <span className="tw-font-medium">Advanced Filters</span>
                                    </button>
                                </div>

                                {/* Description Text */}
                                <p className="tw-text-gray-600 tw-mt-3 md:tw-mt-5 tw-text-sm md:tw-text-base tw-text-center md:tw-text-left">
                                    Find and analyze businesses across Kosovo with our comprehensive search tools
                                </p>
                            </div>
                        </div>
                    </div>

                    {/* Right side - Stats Section */}
                    <div className="col-lg-6 col-md-12 thumb position-relative tw-mt-6 lg:tw-mt-0">
                        <img className="wow tw-opacity-20 tw-w-full tw-h-auto" src={map} alt="Kosovo Map" />
                        <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-mt-0 sm:tw-mt-[-10%] lg:tw-mt-[-25%]">
                            <div className="tw-w-full tw-max-w-3xl tw-px-4">
                                <motion.div 
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                    className="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-3 sm:tw-gap-4 lg:tw-gap-6"
                                >
                                    <StatCard
                                        value={stats.numberOfCompanies}
                                        label="Total Companies"
                                        delay={0}
                                    />
                                    <StatCard
                                        value={stats.numberOfActiveCompanies}
                                        label="Active Companies"
                                        delay={0.2}
                                    />
                                    <StatCard
                                        value={stats.numberOfBankruptCompanies}
                                        label="Bankrupt Companies"
                                        delay={0.4}
                                    />
                                    <StatCard
                                        value={stats.todayPublications}
                                        label="Today's Publications"
                                        delay={0.6}
                                    />
                                </motion.div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Banner;
