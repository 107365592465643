import React, { useState } from 'react';
import styles from './BusinessTable.module.css';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import BusinessDetailsModal from './BusinessDetailsModal';
import api from '../../api';

const tableVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { 
        opacity: 1, 
        x: 0,
        transition: { 
            type: "spring",
            stiffness: 100,
            duration: 0.5 
        }
    },
    exit: { 
        opacity: 0, 
        x: 20,
        transition: { 
            duration: 0.3 
        }
    }
};

const BusinessTable = ({ businesses }) => {
    const [selectedBusiness, setSelectedBusiness] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [businessDetails, setBusinessDetails] = useState(null);

    const handleViewDetails = async (business) => {
        try {
            const response = await api.get(`/api/business/${business.id}/`);
            setBusinessDetails(response.data);
            setSelectedBusiness(business);
            setIsModalOpen(true);
        } catch (error) {
            console.error('Error fetching business details:', error);
        }
    };

    const renderStatus = (status) => {
        const isRegistered = status?.toLowerCase().includes('regjistruar');
        return (
            <span className={`tw-px-3 tw-py-1 tw-rounded-full tw-text-sm tw-font-medium ${
                isRegistered ? 'tw-bg-green-100 tw-text-green-800' : 'tw-bg-red-100 tw-text-red-800'
            }`}>
                {isRegistered ? 'Regjistruar' : 'Çregjistruar'}
            </span>
        );
    };

    return (
        <div className="lg:tw-col-span-3">
            <div id="searchResultsContainer">
                <div id="business-table">
                    <AnimatePresence mode="wait">
                        {/* Desktop/Tablet View */}
                        <div className="tw-hidden md:tw-block">
                            <motion.table
                                key={businesses?.length || 'empty'}
                                variants={tableVariants}
                                initial="hidden"
                                animate="visible"
                                exit="exit"
                                id="searchResultsTable" 
                                className={`${styles.table} table table-bordered table-striped tw-w-full`}
                            >
                                <thead>
                                    <tr>
                                        <th scope="col">EMRI I BIZNESIT</th>
                                        <th scope="col">TIPI I BIZNESIT</th>
                                        <th scope="col">NUMRI UNIK</th>
                                        <th scope="col">KOMUNA</th>
                                        <th scope="col">DATA E REGJISTRIMIT</th>
                                        <th scope="col">NUMRI I PUNETOREVE</th>
                                        <th scope="col">NUMRI I TELEFONIT</th>
                                        <th scope="col">KAPITALI</th>
                                        <th scope="col">STATUSI</th>
                                        <th scope="col" className="tw-text-center">AKSIONE</th>
                                    </tr>
                                </thead>
                                <motion.tbody
                                    variants={{
                                        visible: {
                                            transition: {
                                                staggerChildren: 0.05
                                            }
                                        }
                                    }}
                                >
                                    {businesses && businesses.length > 0 ? (
                                        businesses.map((business, index) => (
                                            <motion.tr
                                                key={business.id}
                                                className="tw-border-b"
                                                variants={{
                                                    hidden: { opacity: 0, y: 20 },
                                                    visible: { 
                                                        opacity: 1, 
                                                        y: 0,
                                                        transition: {
                                                            type: "spring",
                                                            stiffness: 100,
                                                            delay: index * 0.05
                                                        }
                                                    }
                                                }}
                                            >
                                                <td className="tw-px-4 tw-py-2">
                                                    <Link 
                                                        to={`/details?id=${business.id}`}
                                                        className="tw-text-[#438a5e] hover:tw-text-[#2f6c4a]"
                                                    >
                                                        {business.business_name}
                                                    </Link>
                                                </td>
                                                <td className="tw-px-4 tw-py-2">{business.business_type}</td>
                                                <td className="tw-px-4 tw-py-2">{business.unique_identifier}</td>
                                                <td className="tw-px-4 tw-py-2">{business.municipality_name || business.municipality}</td>
                                                <td className="tw-px-4 tw-py-2">{business.registration_date}</td>
                                                <td className="tw-px-4 tw-py-2">{business.number_of_employees}</td>
                                                <td className="tw-px-4 tw-py-2">{business.phone}</td>
                                                <td className="tw-px-4 tw-py-2">{business.capital}</td>
                                                <td className="tw-px-4 tw-py-2">{renderStatus(business.status_in_arbk)}</td>
                                                <td className="tw-px-4 tw-py-2">
                                                    <div className="tw-flex tw-justify-center">
                                                        <button
                                                            onClick={() => handleViewDetails(business)}
                                                            className="tw-bg-transparent tw-border-0 tw-p-0 tw-m-0 tw-cursor-pointer"
                                                            title="View Details"
                                                        >
                                                            <FontAwesomeIcon 
                                                                icon={faEye}
                                                                className="pricing-icon fa-fw tw-text-[#438a5e] hover:tw-text-[#2f6c4a]"
                                                                style={{
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    display: 'block',
                                                                    margin: 'auto'
                                                                }}
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            </motion.tr>
                                        ))
                                    ) : (
                                        <motion.tr
                                            variants={{
                                                hidden: { opacity: 0 },
                                                visible: { opacity: 1 }
                                            }}
                                        >
                                            <td colSpan="10" className="tw-text-center">No businesses found</td>
                                        </motion.tr>
                                    )}
                                </motion.tbody>
                            </motion.table>
                        </div>

                        {/* Mobile View */}
                        <div className="md:tw-hidden tw-space-y-4">
                            {businesses && businesses.length > 0 ? (
                                businesses.map((business) => (
                                    <motion.div
                                        key={business.id}
                                        variants={tableVariants}
                                        initial="hidden"
                                        animate="visible"
                                        exit="exit"
                                        className="tw-bg-white tw-rounded-lg tw-shadow tw-p-4"
                                    >
                                        <div className="tw-flex tw-justify-between tw-items-start tw-mb-3">
                                            <Link 
                                                to={`/details?id=${business.id}`}
                                                className="tw-text-lg tw-font-medium tw-text-[#438a5e] hover:tw-text-[#2f6c4a]"
                                            >
                                                {business.business_name}
                                            </Link>
                                            {renderStatus(business.status_in_arbk)}
                                        </div>

                                        <div className="tw-space-y-2">
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                                <div className="tw-text-sm tw-text-gray-500">Business Type</div>
                                                <div className="tw-text-sm">{business.business_type}</div>
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                                <div className="tw-text-sm tw-text-gray-500">Unique ID</div>
                                                <div className="tw-text-sm">{business.unique_identifier}</div>
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                                <div className="tw-text-sm tw-text-gray-500">Municipality</div>
                                                <div className="tw-text-sm">{business.municipality_name || business.municipality}</div>
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                                <div className="tw-text-sm tw-text-gray-500">Registration Date</div>
                                                <div className="tw-text-sm">{business.registration_date}</div>
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                                <div className="tw-text-sm tw-text-gray-500">Employees</div>
                                                <div className="tw-text-sm">{business.number_of_employees}</div>
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                                <div className="tw-text-sm tw-text-gray-500">Phone</div>
                                                <div className="tw-text-sm">{business.phone}</div>
                                            </div>
                                            <div className="tw-grid tw-grid-cols-2 tw-gap-2">
                                                <div className="tw-text-sm tw-text-gray-500">Capital</div>
                                                <div className="tw-text-sm">{business.capital}</div>
                                            </div>
                                        </div>

                                        <div className="tw-mt-4 tw-flex tw-justify-end">
                                            <button
                                                onClick={() => handleViewDetails(business)}
                                                className="tw-inline-flex tw-items-center tw-px-4 tw-py-2 tw-text-[#438a5e] hover:tw-text-[#2f6c4a] tw-transition-colors"
                                            >
                                                <FontAwesomeIcon icon={faEye} className="tw-mr-2" />
                                                View Details
                                            </button>
                                        </div>
                                    </motion.div>
                                ))
                            ) : (
                                <div className="tw-text-center tw-py-4">No businesses found</div>
                            )}
                        </div>
                    </AnimatePresence>
                </div>
            </div>

            {isModalOpen && (
                <BusinessDetailsModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    businessDetails={businessDetails}
                />
            )}
        </div>
    );
};

export default BusinessTable;
