// src/api/index.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://backend.kseco.ch/';

// Create axios instance with default config
const api = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

// Function to setup token in headers
const setAuthToken = (token) => {
  if (token) {
    api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    localStorage.setItem('access_token', token);
  } else {
    delete api.defaults.headers.common['Authorization'];
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }
};

// Initialize auth header if token exists
const token = localStorage.getItem('access_token');
if (token) {
  setAuthToken(token);
}

// Request interceptor
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // If the error is due to an invalid/expired token
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        // Try to refresh the token
        const refreshToken = localStorage.getItem('refresh_token');
        if (!refreshToken) {
          throw new Error('No refresh token available');
        }

        const response = await axios.post('https://backend.kseco.ch/auth/token/refresh/', {
          refresh: refreshToken
        });

        if (response.data.access) {
          localStorage.setItem('access_token', response.data.access);
          setAuthToken(response.data.access);
          originalRequest.headers['Authorization'] = `Bearer ${response.data.access}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        // If refresh fails, clear tokens and show login modal
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        setAuthToken(null);
        
        // We'll dispatch an event that the AuthContext will listen to
        window.dispatchEvent(new CustomEvent('auth:required'));
        
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const getBusinesses = async (params = {}) => {
  try {
    // Convert arrays to comma-separated strings
    const formattedParams = Object.entries(params).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        acc[key] = value.join(',');
      } else {
        acc[key] = value;
      }
      return acc;
    }, {});

    const response = await api.get('/api/services/', { params: formattedParams });
    return response.data;
  } catch (error) {
    console.error('Error fetching businesses:', error);
    throw error;
  }
};

export const getBusinessCounts = async () => {
  try {
    const response = await api.get('/api/business-counts/');
    return response.data;
  } catch (error) {
    console.error('Error fetching business counts:', error);
    throw error;
  }
};

export const getLatestBusinesses = async () => {
  try {
    const response = await api.get('/api/latest-businesses/');
    return response.data;
  } catch (error) {
    console.error('Error fetching latest businesses:', error);
    throw error;
  }
};

export const getFilterOptions = async () => {
  try {
    const response = await api.get('/api/filter-options/');
    return response.data;
  } catch (error) {
    console.error('Error fetching filter options:', error);
    throw error;
  }
};

export const searchBusinesses = async (query, page = 1) => {
  try {
    const params = new URLSearchParams({
      query: query.trim(),
      page: page
    });
    const response = await api.get(`/api/search-businesses/?${params}`);
    return response.data;
  } catch (error) {
    console.error('Error searching businesses:', error);
    throw error;
  }
};

export const getBusinessDetails = async (businessId) => {
  try {
    const response = await api.get(`/api/business-data/${businessId}/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching business details:', error);
    throw error;
  }
};

export const getOwnerBusinesses = async (ownerId, currentBusinessId) => {
  try {
    const response = await api.get(`/api/owner/${ownerId}/businesses/`, {
      params: {
        current_business_id: currentBusinessId
      }
    });
    return response.data;
  } catch (error) {
    console.error('\n\n\nError fetching owner businesses:', error);
    throw error;
  }
};

export const getBusinessOwners = async (businessId) => {
  try {
    const response = await api.get(`/api/business/${businessId}/owners/`);
    return response.data;
  } catch (error) {
    console.error('\n\nError fetching business owners:', error);
    throw error;
  }
};

export { setAuthToken };
export default api;
